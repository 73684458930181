import React from 'react';

const TerminosYCondiciones = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-20 text-left">
      <h1 className="text-3xl font-bold title-mm mb-8">Términos y Condiciones</h1>

      <div className="space-y-20 mt-20">
        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">1. Funcionamiento General del Ecommerce</h2>
          <p>Nuestro ecommerce es una plataforma innovadora que combina la compra tradicional de productos con un sistema de sorteos y acumulación de puntos. Los usuarios pueden adquirir productos directamente o participar en sorteos para ganarlos.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">2. Sistema de Tickets y Puntos</h2>
          <ul className="list-disc list-inside  space-y-2">
            <li>Tickets: Los usuarios pueden comprar tickets que otorgan puntos.</li>
            <li>Puntos: Los puntos se obtienen al comprar tickets, productos o cuando tus referidos realizan una compra, y se pueden usar para participar en sorteos.</li>
            <li>Participación en Sorteos: Los usuarios pueden aportar sus puntos a sorteos de productos específicos.</li>
            <li>Acumulación de Puntos: Cada compra de tickets o productos suma puntos a tu cuenta.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">3. Programa de Referidos</h2>
          <ul className="list-disc list-inside  space-y-2">
            <li>Link de Referido: Cada usuario tiene un link único de referido.</li>
            <li>Bonificación por Referidos: Ganas puntos adicionales cuando tus amigos se registran usando tu link y realizan compras.</li>
            <li>Acumulación Continua: Los puntos por referidos se acumulan con cada compra que realicen tus referidos.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">4. Proceso de Sorteos</h2>
          <ul className="list-disc list-inside  space-y-2">
            <li>Participación: Puedes participar en múltiples sorteos aportando tus puntos.</li>
            <li>Duración: Cada sorteo tiene una duración específica o hasta completar los puntos requeridos.</li>
            <li>Selección de Ganadores: Los ganadores se seleccionan de manera aleatoria entre los participantes.</li>
            <li>Notificación: Los ganadores son notificados por correo electrónico y en la plataforma.</li>
            <li>Premios: Habrá un ganador que recibirá el producto sorteado, y un ganador que recibirá el 10% de los puntos totales.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">5. Compras y Envíos</h2>
          <ul className="list-disc list-inside  space-y-2">
            <li>Proceso de Compra: Puedes comprar productos directamente o tickets para obtener puntos.</li>
            <li>Envíos: Los productos ganados en sorteos o comprados directamente se envían a la dirección guardada en su perfil.</li>
            <li>Costos de Envío: Los costos de envío pueden variar según la ubicación y el producto.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">6. Política de Devoluciones y Reembolsos</h2>
          <ul className="list-disc list-inside  space-y-2">
            <li>Productos: Las devoluciones de productos comprados directamente siguen nuestra política estándar de devoluciones.</li>
            <li>Tickets y Puntos: Los tickets comprados y los puntos utilizados en sorteos no son reembolsables.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">7. Privacidad y Seguridad</h2>
          <ul className="list-disc list-inside  space-y-2">
            <li>Protección de Datos: Nos comprometemos a proteger tus datos personales según nuestra política de privacidad.</li>
            <li>Seguridad en Transacciones: Todas las transacciones se realizan a través de sistemas seguros y encriptados.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">8. Modificaciones a los Términos y Condiciones</h2>
          <p className="">Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Los cambios entrarán en vigor inmediatamente después de su publicación en la plataforma.</p>
        </section>

        <p className="mt-8 p-4 bg-gray-100 rounded-lg text-gray-600 italic">Al utilizar nuestra plataforma, aceptas estos términos y condiciones. Te recomendamos revisarlos periódicamente para estar al tanto de cualquier actualización.</p>
      </div>
    </div>
  );
};

export default TerminosYCondiciones;