import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';

const ProductosSimilares = ({ skuActual }) => {
  const [productosSimilares, setProductosSimilares] = useState([]);

  useEffect(() => {
    const fetchProductosSimilares = async () => {
      try {
        // Primero, obtén la categoría del producto actual
        const { data: productoActual } = await supabase
          .from('productos')
          .select('categoria_id')
          .eq('sku', skuActual)
          .single();

        if (productoActual) {
          // Luego, busca productos de la misma categoría, excluyendo el actual
          const { data: productos, error } = await supabase
            .from('productos')
            .select('sku, producto, imagen_1, precio_cliente')
            .eq('categoria_id', productoActual.categoria_id)
            .neq('sku', skuActual)
            .limit(3);

          if (error) {
            console.error('Error al obtener productos similares:', error);
          } else {
            setProductosSimilares(productos);
          }
        }
      } catch (error) {
        console.error('Error al obtener productos similares:', error);
      }
    };

    fetchProductosSimilares();
  }, [skuActual]);

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <div className="productos-similares-container">
      <h2 className="text-xl font-bold mb-4 color-mm mb-10">Productos que te podrían interesar</h2>
      <div className="productos-similares-grid flex flex-col sm:flex-row sm:justify-between sm:flex-wrap gap-4">
        {productosSimilares.map((producto) => (
          <Link to={`/productos/detalle-producto/${producto.sku}/comprar`} key={producto.sku} className="producto-similar-card flex-grow sm:flex-grow-0 sm:w-[30%] background-header rounded-lg shadow-md overflow-hidden flex flex-col">
            <img src={producto.imagen_1} alt={producto.producto} className="w-full h-48 object-cover" />
            <div className="p-4 flex flex-col flex-grow">
              <h3 className="producto-similar-nombre text-lg font-semibold mb-2 h-16 overflow-hidden">
                {truncateText(producto.producto, 70)}
              </h3>
              <p className="producto-similar-precio text-xl font-bold color-mm mt-auto">${formatPrice(producto.precio_cliente)}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductosSimilares;