import React from 'react';

function ProductSkeleton() {
  return (
    <div className="card w-96 background-mm shadow-xl producto-card mt-10 animate-pulse">
      <div className="h-48 bg-gray-300 rounded-t-xl"></div>
      <div className="card-body flex items-center justify-center">
        <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
      </div>
      <div className="card-actions justify-center mb-5">
        <div className="h-6 bg-gray-300 rounded w-20 mr-2"></div>
        <div className="h-6 bg-gray-300 rounded w-20"></div>
      </div>
      <div className="producto-info-container ">
        <div className="producto-precio-seccion flex flex-col items-center justify-center">
          <div className="h-4 bg-gray-300 rounded w-16 mb-1"></div>
          <div className="h-6 bg-gray-300 rounded w-20"></div>
        </div>
        <div className="producto-puntos-seccion flex flex-col items-center justify-center">
          <div className="h-4 bg-gray-300 rounded w-16 mb-1"></div>
          <div className="h-6 bg-gray-300 rounded w-20"></div>
        </div>
      </div>

      <div className="progress-container">
        <div className="h-2 bg-gray-300 rounded w-90"></div>
      </div>
    </div>
  );
}

export default ProductSkeleton;