import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { usePuntos } from '../context/PuntosContext';
import './ElMontoncito.css';
import ElMontoncitoSkeleton from '../skeleton/ElMontoncitoSkeleton';
import { supabase } from '../../lib/supabaseClient';
// import { debounce } from 'lodash';
import { motion } from 'framer-motion';

const ElMontoncito = () => {
  const { totalPuntos, actualizarPuntos, userData } = usePuntos();
  const [montones, setMontones] = useState([
    { id: 1, visible: false, apostado: false, apuesta: 0, totalApostado: 0, apuestaUsuario: 0, resultado: null, imagenUrl: '/img/game/elmontoncito/reverso.png', cargando: false, error: false, bordeVisible: false },
    { id: 2, visible: false, apostado: false, apuesta: 0, totalApostado: 0, apuestaUsuario: 0, resultado: null, imagenUrl: '/img/game/elmontoncito/reverso.png', cargando: false, error: false, bordeVisible: false },
    { id: 3, visible: false, apostado: false, apuesta: 0, totalApostado: 0, apuestaUsuario: 0, resultado: null, imagenUrl: '/img/game/elmontoncito/reverso.png', cargando: false, error: false, bordeVisible: false },
    { id: 4, visible: false, apostado: false, apuesta: 0, totalApostado: 0, apuestaUsuario: 0, resultado: null, imagenUrl: '/img/game/elmontoncito/reverso.png', cargando: false, error: false, bordeVisible: false },
    { id: 5, visible: false, apostado: false, apuesta: 0, totalApostado: 0, apuestaUsuario: 0, resultado: null, imagenUrl: '/img/game/elmontoncito/reverso.png', cargando: false, error: false, bordeVisible: false },
    { id: 6, visible: false, apostado: false, apuesta: 0, totalApostado: 0, apuestaUsuario: 0, resultado: null, imagenUrl: '/img/game/elmontoncito/reverso.png', cargando: false, error: false, bordeVisible: false }
  ]);
  const [juegoTerminado, setJuegoTerminado] = useState(false);
  const [, setError] = useState(null);
  const [tiempoRestante, setTiempoRestante] = useState(null);
  const intervalIdRef = useRef(null);
  const [partidaActual, setPartidaActual] = useState(null);
  const [ultimoMontonSinApostar, setUltimoMontonSinApostar] = useState(false);
  const [mostrandoResultados, setMostrandoResultados] = useState(false);
  const [esMobile, setEsMobile] = useState(false);
  const [montonElevado, setMontonElevado] = useState(null);
  const [resultadoTemporal, setResultadoTemporal] = useState(null);
  const [bloqueandoInteraccion, setBloqueandoInteraccion] = useState(false);
  const [resultadoUsuario, setResultadoUsuario] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [juegoIniciado, setJuegoIniciado] = useState(false);
  const [actualizacionApuestas, setActualizacionApuestas] = useState(0);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  // const [clavePartidaActual, setClavePartidaActual] = useState(null);
  const [estaPartidasCerrando, setEstaPartidasCerrando] = useState(false);
  const [montonesEnCarga, setMontonesEnCarga] = useState({});
  const timerRef = useRef(null);
  const revelarEnProgresoRef = useRef(false);
  const [montonesRevelados, setMontonesRevelados] = useState(Array(6).fill(false));
  const [cartasReveladas, setCartasReveladas] = useState(null);
  const [resultadosMontones, setResultadosMontones] = useState({});
  const [retirarApuestaDesactivado, setRetirarApuestaDesactivado] = useState(false);

  const iniciarJuegoRef = useRef();

  const iniciarNuevaPartida = useCallback(async () => {
    try {
      const response = await fetch('/.netlify/functions/validarInicioJuego', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ nuevaPartida: true })
      });
      if (!response.ok) throw new Error('Error al iniciar nueva partida');
      const data = await response.json();
      
      setPartidaActual(data.partida);
      setJuegoIniciado(false);
      setIsLoading(true);
    } catch (error) {
      console.error('Error al iniciar nueva partida:', error);
      setError(error.message);
    }
  }, []);

  const [cerrarPartidaFn, setCerrarPartidaFn] = useState(null);

  const cerrarPartida = useCallback(async () => {
    if (estaPartidasCerrando) return;
    setEstaPartidasCerrando(true);

    try {
      const montonSistema = montones.findIndex(monton => monton.totalApostado === 0) + 1;
      
      const response = await fetch('/.netlify/functions/cerrarPartida', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
           partidaId: partidaActual?.key_partida,
           montonSistema: montonSistema,
           usuario: userData?.codigo_referido
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error desconocido al cerrar la partida');
      }

      setCartasReveladas(data.montoncitos[0]);
      setResultadosMontones(data.resultadosMontones);
      
      const resultadoPartida = {
        montones: montones.map((monton, index) => ({
          ...monton,
          visible: true,
          carta: data.montoncitos[0][`monton_${index + 1}`],
          resultado: data.resultadosMontones[`monton_${index + 1}`]?.resultado || 'error'
        })),
        resultado: data.resultado
      };
      setResultadoTemporal(resultadoPartida);
      setResultadoUsuario(data.resultadoUsuario);
      setMostrandoResultados(true);
      setBloqueandoInteraccion(true);

      await new Promise(resolve => setTimeout(resolve, 16666));
      
      setMostrandoResultados(false);
      setBloqueandoInteraccion(false);
      setResultadoTemporal(null);
      setCartasReveladas(null);
      await iniciarNuevaPartida();
    } catch (error) {
      console.error('Error detallado al cerrar la partida:', error);
      setError(error.message);
      setBloqueandoInteraccion(false);
    } finally {
      setEstaPartidasCerrando(false);
    }
  }, [
    userData?.codigo_referido,
    estaPartidasCerrando,
    montones,
    partidaActual,
    iniciarNuevaPartida
  ]);

  useEffect(() => {
    setCerrarPartidaFn(() => cerrarPartida);
  }, [cerrarPartida]);

  const iniciarTemporizadorSincronizado = useCallback((tiempoInicio, duracion) => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    
    const actualizarTiempo = () => {
      const tiempoTranscurrido = Math.floor((Date.now() - tiempoInicio) / 1000);
      const tiempoRestante = Math.max(0, duracion - tiempoTranscurrido);
      
      setTiempoRestante(tiempoRestante);
      
      if (tiempoRestante <= 0) {
        clearInterval(intervalIdRef.current);
        if (cerrarPartidaFn) cerrarPartidaFn();
      }
    };

    actualizarTiempo();
    intervalIdRef.current = setInterval(actualizarTiempo, 1000);
  }, [cerrarPartidaFn]);

  const iniciarTemporizador = useCallback(async () => {
    const tiempoInicio = Date.now();
    const duracion = 15; // 3 segundos

    try {
      const response = await fetch('/.netlify/functions/iniciarCuentaRegresiva', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          tiempoInicio, 
          duracion,
          partidaId: partidaActual?.key_partida
        })
      });

      const responseText = await response.text();

      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (parseError) {
        console.error('Error al parsear la respuesta JSON:', parseError);
        throw new Error('La respuesta del servidor no es un JSON válido');
      }

      if (!response.ok) {
        throw new Error(responseData.error || 'Error desconocido en la respuesta del servidor');
      }

      if (!responseData.duracionIncluida) {
        console.warn('La duración no se guardó en la base de datos. Usando valor local.');
      }

      if (!responseData.mostrarCartasIncluido) {
        console.warn('El campo mostrar_cartas no existe en la base de datos. La sincronización puede no funcionar correctamente.');
      }

      iniciarTemporizadorSincronizado(tiempoInicio, duracion);

    } catch (error) {
      console.error('Error detallado al iniciar cuenta regresiva:', error);
      setError(error.message);
    }
  }, [iniciarTemporizadorSincronizado, partidaActual]);

  iniciarJuegoRef.current = useCallback(async () => {
    if (juegoIniciado || !userData) return;

    try {
      const response = await fetch('/.netlify/functions/validarInicioJuego');
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al iniciar el juego');
      }
      const data = await response.json();
      
      if (data.error === 'No hay partidas en curso') {
        await iniciarNuevaPartida();
        return;
      }

      if (!data.partida || !data.montoncitos || !data.apuestas) {
        throw new Error('Datos de partida incompletos');
      }

      setPartidaActual(data.partida);
      
      const montonesFormateados = [
        { monton: data.montoncitos.monton_1 },
        { monton: data.montoncitos.monton_2 },
        { monton: data.montoncitos.monton_3 },
        { monton: data.montoncitos.monton_4 },
        { monton: data.montoncitos.monton_5 },
        { monton: data.montoncitos.monton_6 }
      ].map(({ monton }, index) => {
        const apuestasMonton = data.apuestas.filter(a => 
          a.montoncito === `monton_${index + 1}` && 
          a.resultado !== 'cancelada'
        );
        const totalApostado = apuestasMonton.reduce((sum, a) => sum + a.puntos_apostados, 0);
        const apuestaUsuario = userData && userData.codigo_referido
          ? apuestasMonton
              .filter(a => a.jugador === userData.codigo_referido)
              .reduce((sum, a) => sum + a.puntos_apostados, 0)
          : 0;

        return {
          id: index + 1,
          visible: false,
          apostado: totalApostado > 0,
          apuesta: apuestaUsuario,
          totalApostado: totalApostado,
          apuestaUsuario: apuestaUsuario,
          resultado: null,
          imagenUrl: '/img/game/elmontoncito/reverso.png',
          cargando: false,
          error: false,
          bordeVisible: false
        };
      });

      setMontones(montonesFormateados);
      setJuegoTerminado(false);
      setError(null);
      setUltimoMontonSinApostar(false);
      setTiempoRestante(null);
      setMostrandoResultados(false);
      setIsLoading(false);
      setJuegoIniciado(true);

    } catch (error) {
      console.error('Error al iniciar el juego:', error);
      setError(error.message);
      setIsLoading(false);
    }
  }, [juegoIniciado, userData, iniciarNuevaPartida]);

  useEffect(() => {
    if (!juegoIniciado) {
      iniciarJuegoRef.current();
    }
  }, [juegoIniciado]);

  useEffect(() => {
    if (userData && !juegoIniciado) {
      setJuegoIniciado(false);
      iniciarJuegoRef.current();
    }
  }, [userData, juegoIniciado]);

  const detectarMobile = useCallback(() => {
    const esMobile = window.innerWidth <= 768;
    setEsMobile(esMobile);
  }, []);

  useEffect(() => {
    detectarMobile();
    window.addEventListener('resize', detectarMobile);
    return () => window.removeEventListener('resize', detectarMobile);
  }, [detectarMobile]);

  const actualizarApuestasEnTiempoReal = useCallback(async (payload) => {
    if (!userData || !partidaActual || !payload.new || typeof payload.new !== 'object') {
      return;
    }

    const payloadPartidaId = payload.new.partida;
    
    if (payloadPartidaId !== partidaActual.key_partida) {
      console.log("El ID de partida del payload no coincide con la key_partida actual");
      return;
    }

    try {
      const response = await fetch('/.netlify/functions/actualizarMontoncitos', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ partidaId: partidaActual.key_partida })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error del servidor:', errorData);
        throw new Error(`Error del servidor: ${errorData.error}`);
      }

      const data = await response.json();

      if (!data.montoncitos || !data.totales || !data.apuestas) {
        console.error('Datos incompletos recibidos del servidor');
        return;
      }

      setMontones(prevMontones => {
        return prevMontones.map((monton, index) => {
          const montonKey = `monton_${index + 1}`;
          const apuestasUsuario = data.apuestas.filter(a => a.montoncito === montonKey && a.jugador === userData?.codigo_referido);
          const apuestaUsuario = apuestasUsuario.reduce((sum, a) => sum + a.puntos_apostados, 0);
          return {
            ...monton,
            apostado: data.totales[montonKey] > 0,
            totalApostado: data.totales[montonKey] || 0,
            apuestaUsuario: apuestaUsuario
          };
        });
      });

      const montonesApostados = Object.values(data.totales).filter(total => total > 0).length;
      if (montonesApostados === 5 && !ultimoMontonSinApostar) {
        setUltimoMontonSinApostar(true);
        iniciarTemporizador();
      } else if (montonesApostados < 5 && ultimoMontonSinApostar) {
        setUltimoMontonSinApostar(false);
        setTiempoRestante(null);
      }

      setActualizacionApuestas(prev => prev + 1);
      forceUpdate();
    } catch (error) {
      console.error('Error al actualizar montoncitos:', error);
      setError(`Error al actualizar montoncitos: ${error.message}`);
    }
  }, [partidaActual, userData, ultimoMontonSinApostar, iniciarTemporizador]);

  useEffect(() => {
    const channel = supabase
      .channel('elmontoncito-channel')
      .on('broadcast', { event: 'countdown-start' }, (payload) => {
        iniciarTemporizadorSincronizado(payload.tiempoInicio, payload.duracion);
      })
      .on('broadcast', { event: 'nueva-partida' }, (payload) => {
        setPartidaActual(payload.partida);
        setJuegoIniciado(false);
        setIsLoading(true);
      })
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'apuestas' 
      }, (payload) => {
        actualizarApuestasEnTiempoReal(payload);
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [actualizarApuestasEnTiempoReal, iniciarTemporizadorSincronizado]);

  useEffect(() => {
  }, [actualizacionApuestas]);

  useEffect(() => {
  }, [montones]);

  const mostrarMontonesSecuencialmente = useCallback(async () => {
    try {
        const response = await fetch('/.netlify/functions/revelarMontoncitos', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ resultadosMontones: resultadosMontones,
              partidaId: partidaActual?.key_partida
             })
        });

        if (!response.ok) {
            throw new Error('Error al revelar montones');
        }

        const data = await response.json();
        console.log(data);
        // Manejar la respuesta si es necesario
        const resultados = data.resultados; // Asegúrate de que 'resultados' esté definido

        // Encuentra el índice del monton banca
        const montonBancaIndex = Object.keys(resultados).findIndex(key => resultados[key].resultado === 'banca');
        console.log("montonBancaIndex",montonBancaIndex);
        // Reinicia el estado de montones revelados
        setMontonesRevelados(Array(6).fill(false)); 
        setMostrandoResultados(true);
        
        // Función para revelar los montones uno por uno
        const revelarMontonesSecuencialmente = async () => {
            const delay = 1666; // 1 segundo de retraso entre revelaciones

            // Revelar el monton banca primero
            setMontonesRevelados(prev => {
                const nuevosMontones = [...prev];
                nuevosMontones[montonBancaIndex] = true; // Revela el monton banca
                return nuevosMontones;
            });
            await new Promise(resolve => setTimeout(resolve, delay));

            // Revelar los demás montones en orden ascendente
            for (let i = 0; i < 6; i++) {
                if (i !== montonBancaIndex) { // No revelar el monton banca de nuevo
                    setMontonesRevelados(prev => {
                        const nuevosMontones = [...prev];
                        nuevosMontones[i] = true; // Revela el monton
                        return nuevosMontones;
                    });
                    await new Promise(resolve => setTimeout(resolve, delay));
                }
            }

            // Esperar 5 segundos antes de cambiar a la nueva partida
            await new Promise(resolve => setTimeout(resolve, 6666));
            await iniciarNuevaPartida(); // Cambia a la nueva partida
        };

        // Llamar a la función para revelar los montones secuencialmente
        revelarMontonesSecuencialmente();
        
        // Ocultar botones de apuesta y total apostado
        setBloqueandoInteraccion(true);
    } catch (error) {
        console.error('Error al llamar a la función de Netlify:', error);
    }
}, [resultadosMontones, iniciarNuevaPartida, partidaActual?.key_partida]);

  useEffect(() => {
    const currentTimerRef = timerRef.current; // Copia el valor de timerRef.current

    if (mostrandoResultados && partidaActual?.estado === 'Cerrada') {
      console.log('Iniciando revelación de montones...');
      setMontonesRevelados(new Array(6).fill(false));
      mostrarMontonesSecuencialmente();
    }
    
    return () => {
      if (currentTimerRef) { // Usa la variable copiada
        clearTimeout(currentTimerRef);
      }
    };
  }, [mostrandoResultados, partidaActual, mostrarMontonesSecuencialmente]);

  const apostar = useCallback(async (montonIndex, cantidad, numeroMonton) => {
    if (cantidad <= 0 || cantidad > totalPuntos || juegoTerminado) return;
  
    if (!userData || !userData.codigo_referido) {
      console.error('Código de usuario no disponible');
      return;
    }

    setMontonesEnCarga(prev => ({ ...prev, [numeroMonton]: true }));

    try {
      const response = await fetch('/.netlify/functions/realizarApuesta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jugador: userData.codigo_referido,
          cantidad: cantidad,
          numeroMonton: numeroMonton,
          partidaId: partidaActual?.key_partida,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al realizar la apuesta');
      }

      setMontones(prevMontones => {
        const nuevosMontones = [...prevMontones];
        const index = montonIndex - 1;
        if (nuevosMontones[index]) {
          nuevosMontones[index] = {
            ...nuevosMontones[index],
            apostado: true,
            apuesta: (nuevosMontones[index].apuesta || 0) + cantidad,
            totalApostado: (nuevosMontones[index].totalApostado || 0) + cantidad,
            apuestaUsuario: (nuevosMontones[index].apuestaUsuario || 0) + cantidad
          };
        }
        return nuevosMontones;
      });

      actualizarPuntos(totalPuntos - cantidad);

      setTimeout(() => {
        setMontonesEnCarga(prev => ({ ...prev, [numeroMonton]: false }));
      }, 3000);

    } catch (error) {
      console.error('Error al apostar:', error);
      setError(error.message);
      setMontonesEnCarga(prev => ({ ...prev, [numeroMonton]: false }));
    }
  }, [totalPuntos, juegoTerminado, partidaActual, userData, actualizarPuntos]);

  const retirarApuesta = useCallback(async (montonIndex) => {
    if (!userData || !userData.codigo_referido || !partidaActual) {
      console.error('Datos de usuario o partida no disponibles');
      return;
    }

    try {
      const response = await fetch('/.netlify/functions/retirarApuesta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jugador: userData.codigo_referido,
          partidaId: partidaActual.key_partida,
          numeroMonton: montonIndex,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al retirar la apuesta');
      }

      const data = await response.json();
      console.log('Apuesta retirada con éxito:', data);

      actualizarPuntos(totalPuntos + data.puntosDevueltos);

      setMontones(prevMontones => {
        const nuevosMontones = [...prevMontones];
        nuevosMontones[montonIndex - 1] = {
          ...nuevosMontones[montonIndex - 1],
          apostado: nuevosMontones[montonIndex - 1].totalApostado > data.puntosDevueltos,
          apuesta: 0,
          totalApostado: Math.max(0, nuevosMontones[montonIndex - 1].totalApostado - data.puntosDevueltos),
          apuestaUsuario: 0
        };

        const montonesApostados = nuevosMontones.filter(monton => monton.totalApostado > 0).length;
        console.log("Montones apostados después de retirar:", montonesApostados);

        if (montonesApostados < 5 && ultimoMontonSinApostar) {
          setUltimoMontonSinApostar(false);
          setTiempoRestante(null);
          console.log("Deteniendo temporizador");
        }

        return nuevosMontones;
      });
    } catch (error) {
      console.error('Error al retirar la apuesta:', error);
      setError(error.message);
    }
  }, [userData, partidaActual, totalPuntos, actualizarPuntos, ultimoMontonSinApostar]);

  const elevarMonton = useCallback((index) => {
    if (esMobile) {
      setMontonElevado(montonElevado === index ? null : index);
    }
  }, [esMobile, montonElevado]);

  useEffect(() => {
    const channel = supabase
      .channel('partidas')
      .on('postgres_changes', { 
        event: 'UPDATE', 
        schema: 'public', 
        table: 'partidas',
        filter: `key_partida=eq.${partidaActual?.key_partida}`
      }, (payload) => {
        if (payload.new.mostrar_cartas || payload.new.tiempo_inicio) {
          console.log('Mostrando cartas para todos los clientes');
          iniciarTemporizadorSincronizado(payload.new.tiempo_inicio, payload.new.duracion); // Usa la duración actualizada
          
          // Desactivar el botón de retirar apuesta
          setRetirarApuestaDesactivado(true); // Nueva variable de estado para desactivar el botón
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [partidaActual, iniciarTemporizadorSincronizado]);

  const actualizarInterfazUsuario = useCallback((data) => {
    setPartidaActual(data.partida);
    setMontones(prevMontones => {
      return prevMontones.map((monton, index) => {
        const montonKey = `monton_${index + 1}`;
        const apuestasUsuario = data.apuestas.filter(a => a.montoncito === montonKey && a.jugador === userData?.codigo_referido);
        const apuestaUsuario = apuestasUsuario.reduce((sum, a) => sum + a.puntos_apostados, 0);
        return {
          ...monton,
          apostado: data.totales[montonKey] > 0,
          totalApostado: data.totales[montonKey] || 0,
          apuestaUsuario: apuestaUsuario
        };
      });
    });
    // Otras actualizaciones necesarias
  }, [userData?.codigo_referido, setPartidaActual, setMontones]);
  
  // const actualizarEstadoJuego = useCallback(
  //   debounce((clavePartidaActual) => {
  //     fetch('/.netlify/functions/validarInicioJuego', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ clavePartidaCliente: clavePartidaActual })
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         if (data.sinCambios) {
  //           console.log('No hay cambios en la partida');
  //         } else {
  //           console.log('SI hay cambios en la partida');
  //           setClavePartidaActual(data.partida.key_partida);
  //           setPartidaActual(data.partida);
  //           setMontones(prevMontones => {
  //             return prevMontones.map((monton, index) => {
  //               const montonKey = `monton_${index + 1}`;
  //               const apuestasUsuario = data.apuestas.filter(a => a.montoncito === montonKey && a.jugador === userData?.codigo_referido);
  //               const apuestaUsuario = apuestasUsuario.reduce((sum, a) => sum + a.puntos_apostados, 0);
  //               return {
  //                 ...monton,
  //                 apostado: data.totales[montonKey] > 0,
  //                 totalApostado: data.totales[montonKey] || 0,
  //                 apuestaUsuario: apuestaUsuario
  //               };
  //             });
  //           });
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error al actualizar estado del juego:', error);
  //         setError(error.message);
  //       });
  //   }, 10000),
  //   [clavePartidaActual, userData?.codigo_referido]
  // );
  


  // useEffect(() => {
  //   const debouncedActualizarEstadoJuego = debounce(actualizarEstadoJuego, 10000);
  //   debouncedActualizarEstadoJuego(); // Llama a la función debounced

  //   return () => {
  //     debouncedActualizarEstadoJuego.cancel(); // Limpieza
  //   };
  // }, [actualizarEstadoJuego]);

  const cargarNuevaPartida = useCallback(() => {
    fetch('/.netlify/functions/obtenerNuevaPartida')
      .then(response => response.json())
      .then(data => {
        setPartidaActual(data.partida);
        actualizarInterfazUsuario(data);
      })
      .catch(console.error);
  }, [actualizarInterfazUsuario]);

  useEffect(() => {
    if (partidaActual?.estado === 'finalizada') {
      const timeoutId = setTimeout(() => {
        cargarNuevaPartida();
      }, 6000);
      return () => clearTimeout(timeoutId);
    }
  }, [partidaActual?.estado, cargarNuevaPartida]);


  useEffect(() => {
    const currentTimer = timerRef.current;
    if (mostrandoResultados && !revelarEnProgresoRef.current) {
      setMontonesRevelados([false, false, false, false, false, false]);
      mostrarMontonesSecuencialmente();
    } else if (!mostrandoResultados) {
      setMontonesRevelados([false, false, false, false, false, false]);
      revelarEnProgresoRef.current = false;
    }
    return () => {
      if (currentTimer) {
        clearTimeout(currentTimer);
      }
    };
  }, [mostrandoResultados, mostrarMontonesSecuencialmente]);

  useEffect(() => {
    if (!juegoIniciado) {
      setMontonesRevelados([false, false, false, false, false, false]);
      revelarEnProgresoRef.current = false;
    }
  }, [juegoIniciado]);

  if (isLoading) {
    return <ElMontoncitoSkeleton />;
  }

  return (
    <div className="el-montoncito h-full w-full flex flex-col">
      <h2 className='title-mm'>El Montoncito</h2>
      {userData ? (
        <p>Puntos disponibles: {totalPuntos}</p>
      ) : (
        <p>Inicia sesión para apostar</p>
      )}
      {bloqueandoInteraccion && (
        <div className="overlay fixed inset-0 bg-black bg-opacity-50 z-50"></div>
      )}
      {mostrandoResultados && resultadoTemporal ? (
        <div className="resultados relative z-50 flex flex-col">
          {resultadoUsuario && resultadoUsuario.totalApostado > 0 && (
            <div className="resultado-usuario w-full">
              <p>Has {resultadoUsuario.resultado > 0 ? 'Ganado' : resultadoUsuario.resultado < 0 ? 'Perdido' : 'Empatado'}</p>
              <p><span className="color-mm">{resultadoUsuario.resultado}</span> puntos</p>
              <p>Total apostado: {resultadoUsuario.totalApostado}</p>
            </div>
          )}
          <div className="area-juego flex flex-wrap items-center justify-center gap-4 p-4 mb-40">
            {resultadoTemporal.montones.map((monton, index) => {
              const esBanca = monton.resultado === 'banca'; // Verifica si es el montón banca
              const esRevelado = montonesRevelados[index]; // Verifica si el montón ha sido revelado

              return (
                <motion.div 
                  key={index} 
                  className={`monton ${esRevelado || esBanca ? monton.resultado : ''}`} // Aplica el resultado como clase si se cumple alguna condición
                  style={{ perspective: '1000px' }}
                >
                  <motion.div 
                    className="carta-contenedor"
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      position: 'relative',
                      transformStyle: 'preserve-3d'
                    }}
                    initial={{ rotateY: 0 }}
                    animate={{ rotateY: montonesRevelados[index] ? 180 : 0 }}
                    transition={{ duration: 0.6 }}
                  >
                    <div
                      style={{ 
                        position: 'absolute', 
                        width: '100%', 
                        height: '100%', 
                        backfaceVisibility: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <img 
                        src={`/img/game/elmontoncito/reverso.png`}
                        alt="Reverso de la carta"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      />
                    </div>
                    <div
                      style={{ 
                        position: 'absolute', 
                        width: '100%', 
                        height: '100%', 
                        backfaceVisibility: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: 'rotateY(180deg)'
                      }}
                    >
                      <img 
                        src={`/img/game/elmontoncito/${cartasReveladas[`monton_${index + 1}`]}.png`}
                        alt="Carta revelada"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  </motion.div>
                  {monton.apuestaUsuario > 0 && (
                    <motion.div 
                      className="resultado-apuesta" 
                      style={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        left: 0, 
                        right: 0, 
                        textAlign: 'center',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        color: 'white',
                        padding: '5px',
                        transformStyle: 'preserve-3d',
                        backfaceVisibility: 'hidden'
                      }}
                      initial={{ opacity: 1 }}
                      animate={{ 
                        opacity: 1,
                        rotateY: montonesRevelados[index] ? 180 : 0
                      }}
                      transition={{ duration: 0.6 }}
                    >
                      {montonesRevelados[index] ? (
                        resultadoUsuario  
                          ? `Ganaste ${monton.apuestaUsuario * 2}` 
                          : `Perdiste ${monton.apuestaUsuario}`
                      ) : (
                        `Tu apuesta: ${monton.apuestaUsuario}`
                      )}
                    </motion.div>
                  )}
                </motion.div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="area-juego flex-grow flex flex-wrap items-center justify-center">
          {montones.map((monton, index) => {
            const montonIndex = index + 1; 
            const montonesApostados = montones.filter(monton => monton.totalApostado > 0).length;
            const esUltimoMontonSinApostar = montonesApostados === 5 && monton.totalApostado === 0;
            const puedeApostar = userData && !juegoTerminado && !mostrandoResultados && (monton.totalApostado > 0 || !ultimoMontonSinApostar);
            const esElevado = montonElevado === montonIndex;

            return (
              <div 
                key={montonIndex} 
                id={`monton_${montonIndex}`}
                className={`monton flex items-center justify-center
                            ${monton.apostado ? 'apostado' : ''} 
                            ${esElevado ? 'elevado z-10' : 'z-1'}
                            transition-all duration-300 ease-in-out
                            w-1/6 min-w-[150px] max-w-[200px] aspect-[3/4]`}
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    elevarMonton(montonIndex);
                  }
                }}
              >
                <div className="relative w-full h-full flex items-center justify-center">
                  <div className="total-apostado absolute top-0 left-0">
                    Total Apuestas <br/> 
                    <span className="monto-total-apostado">{monton.totalApostado || 0}</span>
                  </div>
                  {userData && !juegoTerminado && !esUltimoMontonSinApostar && (
                    <div className="botones-apuesta absolute bottom-0 left-0 flex">
                      {montonesEnCarga[montonIndex] ? (
                        <span className="loading loading-spinner loading-xs text-mm"></span>
                      ) : (
                        <>
                          <button 
                            className="apuesta-minima mr-1 px-2 py-1 text-sm"
                            onClick={(e) => { e.stopPropagation(); apostar(montonIndex, 1, montonIndex); }}
                            disabled={!puedeApostar}
                          >
                            +1
                          </button>
                          <button 
                            className="apuesta-maxima px-2 py-1 text-sm"
                            onClick={(e) => { e.stopPropagation(); apostar(montonIndex, 10, montonIndex); }}
                            disabled={!puedeApostar}
                          >
                            +10
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {userData && monton.apuestaUsuario > 0 && !mostrandoResultados && !retirarApuestaDesactivado && (
                    <button className={`retirar-apuesta ${monton.apuestaUsuario > 0 ? 'visible' : ''}`}  onClick={(e) => { e.stopPropagation(); retirarApuesta(montonIndex); }}>Cancelar</button>
                  )}
                    <img 
                      src={montonesRevelados[index] && cartasReveladas 
                        ? `/img/game/elmontoncito/${cartasReveladas[`monton_${index + 1}`]}.png`
                        : '/img/game/elmontoncito/reverso.png'}
                      alt={montonesRevelados[index] ? "Carta revelada" : "Reverso de la carta"}
                      className="max-w-full max-h-full object-contain"
                    />
                  {userData && monton.apuestaUsuario > 0 && (
                    <div className={`apuesta-actual ${monton.apuestaUsuario > 0 ? 'visible' : ''}`}>
                      Tu: {monton.apuestaUsuario}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {ultimoMontonSinApostar && tiempoRestante !== null && (
        <div className="temporizador">
          Tiempo restante para apostar: {tiempoRestante} segundos
        </div>
      )}
    </div>
  );
};

export default ElMontoncito;