import React from 'react';

function ElMontoncitoSkeleton() {
  return (
    <div className="container-generico animate-pulse flex flex-col items-center min-h-screen mt-10">
    <div className="mb-8">
      <div className="h-10 bg-gray-300 rounded w-64 mx-auto"></div>
    </div>
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 p-4"> {/* Ajustado para ser responsivo */}
      {[...Array(6)].map((_, index) => (
        <div key={index} className="card bg-base-100 shadow-xl animate-pulse mr-12" style={{ width: '150px', height: '222px' }}> {/* Añadido margen derecho */}
          <div className="card-body p-4">
            <div className="h-24 bg-gray-300 rounded-lg mb-4"></div>
            <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="card-actions justify-end">
              <div className="h-8 bg-gray-300 rounded w-24"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
}

export default ElMontoncitoSkeleton;