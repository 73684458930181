import React from 'react';
import { Link } from 'react-router-dom';
import './ComoParticipar.css';

const ComoParticipar = () => {
  return (
    <div className="como-participar-container">
      <h1 className='title-mm'>¿Cómo Participar?</h1>
      
      <section className="section">
        <h2>Metodología de los Puntos</h2>
        <p>
          En MatiMarket, acumular puntos es fácil y divertido. Por cada compra que realices, ganarás puntos que podrás utilizar para participar en el sorteo de más productos!
          <br />
          También podrás sumar puntos cada vez que tus referidos compren productos o tickets, por lo que te conviene compartir con tus amigos!
        </p>
        <ul className='mt-10'>
          <li><strong>Compra productos:</strong> Cada producto tiene asociado la cantidad de puntos de regalo que obtendrás.</li>
          <li><strong>Sorteos:</strong> Cuando un producto reciba el 100% de los puntos, se realizará el sorteo a través de un algoritmo realtime en dónde se elegirá el ganador del producto y además un ganador del 10% de los puntos del producto.</li>
          <li><strong>Referidos:</strong> Invita a tus amigos y recibe puntos de por vida, cada vez que realice compras de productos o tickets.</li>
        </ul>
      </section>
      
      <section className="section mt-20">
        <h2>El Sorteo</h2>
        <p>
          Cuando un producto complete el 100% del los puntos, 3 días después entre las 22 y 24hrs se realizará el sorteo online, en dónde un algoritmo que ya fue creado elegirá dos numeros aleatorios y asignará al ganador del producto y al ganador del 10% de los puntos recolectados, los cuales quedarán abonados a la cuenta de los ganadores de forma automática.
        </p>
        <ul className='mt-10'>
          <li><strong>Fecha del sorteo:</strong> 3 días después de completar el 100% de los puntos entre las 22 y 24 hrs.</li>
          <li><strong>Premios:</strong> El producto que se esta sorteando será enviado al domicilio del ganador, y al ganador de los puntos, se le abonarán a su cuenta al instante.</li>
          <li><strong>Notificación:</strong> Los ganadores serán notificados por correo electrónico y los datos del sorteo quedarán visibles para todo el mundo y para siempre en nuestro sitio web.</li>
        </ul>
      </section>
      
      <section className="section">
        <h2>Condiciones</h2>
        <p>
          Para poder participar en el sorteo, asegúrate de cumplir con las siguientes condiciones:
        </p>
        <ul className='mt-10'>
          <li>Ser mayor de 18 años.</li>
          <li>Estar registrado en MatiMarket con una cuenta válida.</li>
          <li>Haber aportado el mínimo de puntos al producto sorteado.</li>
        </ul>
      </section>
      
      <section className="section mt-20">
        <h2>¡Empieza a acumular puntos hoy!</h2>
        <p>
          No esperes más, realiza tus compras en MatiMarket y empieza a acumular puntos para participar en nuestros emocionantes sorteos.
        </p>
        <br />

        <div className="container-buttons">
            <Link to={"/productos"} className="links">
            <button className="btn btn-primary">Ver Productos</button>
            </Link>
            <Link to={"/comprar-tickets"} className="links">
            <button className="btn btn-accent mt-5">Comprar Tickets</button>
            </Link>
        </div>


      </section>
    </div>
  );
};

export default ComoParticipar;
