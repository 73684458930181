import React from 'react';

function MisPuntosSkeleton() {
  return (
    <div className="container-generico animate-pulse min-h-screen">
      <div className="flex justify-center mb-8 mt-20">
        <div className="h-10 bg-gray-300 rounded w-1/3"></div>
      </div>
      
      <table className="tabla-responsive tabla-mis-puntos w-full">
        <thead>
          <tr>
            {[...Array(10)].map((_, index) => (
              <th key={index} className="h-8 bg-gray-300 rounded"></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              {[...Array(10)].map((_, cellIndex) => (
                <td key={cellIndex} className="p-2">
                  <div className="h-6 bg-gray-300 rounded"></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MisPuntosSkeleton;