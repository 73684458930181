import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import DOMPurify from 'dompurify';
import { CarritoContext } from './context/CarritoContext';
import { PuntosContext } from './context/PuntosContext';
import './Participa.css';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import ParticipaProductSkeleton from './skeleton/ParticipaProductSkeleton';
import ProductosSimilares from './ProductosSimilares';
import { useInView } from 'react-intersection-observer';

const Participa = () => {
  const { sku } = useParams();
  const [producto, setProducto] = useState(null);
  const [imagenPrincipal, setImagenPrincipal] = useState(null);
  const [nombreImagenHover, setNombreImagenHover] = useState('');
  const [puntos, setPuntos] = useState('');
  const [minimoPuntos, setMinimoPuntos] = useState(0);
  const [puntosAportados, setPuntosAportados] = useState(0);
  const [tickets, setTickets] = useState([]);
  const { agregarItem } = useContext(CarritoContext);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);
  const speedTimeoutRef = useRef(null);
  const [speed, setSpeed] = useState(100);
  const [modalAbierto, setModalAbierto] = useState(false);
  const { totalPuntos, userData } = useContext(PuntosContext);
  const [mostrarFechaSorteo, setMostrarFechaSorteo] = useState(false);
  const [sorteosCerrados, setSorteosCerrados] = useState([]);
  const [sorteosFinalizados, setSorteosFinalizados] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const checkmarkRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [titleRef, titleInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [imageRef, imageInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [infoRef, infoInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [progressRef, progressInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ticketsRef, ticketsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [descripcionRef, descripcionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [similaresRef, similaresInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const procesarDescripcion = (descripcion) => {
    if (!descripcion) return '';

    let contenidoProcesado = descripcion
      // Procesar h2
      .replace(/\{([^}]+)\}/g, (match, titulo) => {
        return `<h2 class="color-mm text-center font-bold titulo-descripcion">${titulo}</h2>`;
      })
      // Procesar h3
      .replace(/\[([^\]]+)\]/g, (match, titulo) => {
        return `<h3 class="color-mm font-bold mb-5 mt-10">${titulo}</h3>`;
      })
      // Procesar párrafos
      .replace(/\|\|([^|]+)\|\|/g, (match, parrafo) => {
        return `<p class="mb-5" >${parrafo}</p>`;
      })
      // Procesar listas
      .replace(/^\* (.+)$/gm, '<li>$1</li>')
      .replace(/(<li>.*<\/li>\n?)+/g, match => `<ul>${match}</ul>`);

    return contenidoProcesado;
  };


  const fetchProductoYPuntos = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: productoData, error: productoError } = await supabase
        .from('productos')
        .select('*')
        .eq('sku', sku)
        .single();

      if (productoError) throw productoError;

      setProducto(productoData);
      setMinimoPuntos(productoData.minimo_puntos);
      setPuntos(productoData.minimo_puntos);
      setImagenPrincipal(productoData.imagen_1);
      setNombreImagenHover(productoData.producto);

      const { data: sorteoData, error: sorteoError } = await supabase
        .from('sorteos')
        .select('key')
        .eq('producto', sku)
        .eq('estado', 'Disponible')
        .single();

      if (sorteoError && sorteoError.code !== 'PGRST116') throw sorteoError;

      if (sorteoData) {
        const { data: ticketsData, error: ticketsError } = await supabase
          .from('tickets_participantes')
          .select('puntos_aportados')
          .eq('sorteo', sorteoData.key);

        if (ticketsError) throw ticketsError;

        const totalPuntosAportados = ticketsData.reduce((acc, ticket) => acc + ticket.puntos_aportados, 0);
        setPuntosAportados(totalPuntosAportados);
      }
    } catch (error) {
      console.error('Error fetching producto y puntos:', error.message);
    } finally {
      setIsLoading(false);
    }
  }, [sku]);

  const fetchTickets = useCallback(async () => {
    try {
      const { data, error } = await supabase.from('tickets').select('*').eq('publico', true).order('id');
      if (error) {
        console.error('Error fetching tickets:', error.message);
      } else {
        setTickets(data);
      }
    } catch (error) {
      console.error('Error fetching tickets:', error.message);
    }
  }, []);

  const fetchSorteos = useCallback(async () => {
    try {
      const { data: sorteosCerradosData, error: sorteosCerradosError } = await supabase
        .from('sorteos')
        .select('*')
        .eq('estado', 'Cerrado')
        .eq('producto', sku);

      if (sorteosCerradosError) {
        console.error('Error fetching sorteos cerrados:', sorteosCerradosError.message);
      } else {
        setSorteosCerrados(sorteosCerradosData);
      }

      const { data: sorteosFinalizadosData, error: sorteosFinalizadosError } = await supabase
        .from('sorteos')
        .select('*')
        .eq('estado', 'Finalizado')
        .eq('producto', sku);

      if (sorteosFinalizadosError) {
        console.error('Error fetching sorteos finalizados:', sorteosFinalizadosError.message);
      } else {
        setSorteosFinalizados(sorteosFinalizadosData);
      }
    } catch (error) {
      console.error('Error fetching sorteos:', error.message);
    }
  }, [sku]);

  useEffect(() => {
    fetchProductoYPuntos();
    fetchTickets();
    fetchSorteos();

    const productosChannel = supabase.channel('productos_changes')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'productos', filter: `sku=eq.${sku}` }, fetchProductoYPuntos)
      .subscribe();

    const sorteosChannel = supabase.channel('sorteos_changes')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'sorteos', filter: `producto=eq.${sku}` }, fetchProductoYPuntos)
      .subscribe();

    const participantesChannel = supabase.channel('participantes_changes')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'tickets_participantes' }, fetchProductoYPuntos)
      .subscribe();

    const ticketsComprasChannel = supabase
      .channel('tickets_compras_channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'tickets_compras' }, (payload) => {
        console.log('Change received in tickets_compras!', payload);
        fetchTickets();
        fetchProductoYPuntos();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(productosChannel);
      supabase.removeChannel(sorteosChannel);
      supabase.removeChannel(participantesChannel);
      ticketsComprasChannel.unsubscribe();
    };
  }, [fetchProductoYPuntos, fetchTickets, fetchSorteos, sku]);

  useEffect(() => {
    if (puntosAportados >= (producto ? producto.puntos : 0)) {
      setMostrarFechaSorteo(true);
    } else {
      setMostrarFechaSorteo(false);
    }
  }, [puntosAportados, producto]);

  const handleHoverImagen = (imagen) => {
    setImagenPrincipal(imagen);
  };

  const handlePuntosChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      const maxPuntosUsuario = totalPuntos;
      const maxPuntosAportar = producto.puntos - puntosAportados;
      const maxPuntosPermitidos = Math.min(maxPuntosUsuario, maxPuntosAportar);

      if (value > maxPuntosPermitidos) {
        setPuntos(maxPuntosPermitidos);
      } else {
        setPuntos(value);
      }
    } else {
      setPuntos('');
    }
  };

  const handlePuntosBlur = () => {
    const value = parseInt(puntos, 10);
    if (!isNaN(value)) {
      if (value < minimoPuntos) {
        setPuntos(minimoPuntos);
      } else {
        setPuntos(value);
      }
    } else {
      setPuntos(minimoPuntos);
    }
  };

  const incrementPuntos = () => {
    setPuntos((prevPuntos) => {
      const value = parseInt(prevPuntos, 10);
      if (!isNaN(value)) {
        return value + 1;
      }
      return prevPuntos;
    });
  };

  const decrementPuntos = () => {
    setPuntos((prevPuntos) => {
      const value = parseInt(prevPuntos, 10);
      if (!isNaN(value) && value > minimoPuntos) {
        return value - 1;
      }
      return prevPuntos;
    });
  };

  const handleAbonarPuntos = () => {
    setModalAbierto(true);
  };

  const sendEmailPuntosAbonados = async (email, sku, puntos, puntosInicio, puntosFinal) => {
    try {
      const response = await fetch('/.netlify/functions/sendEmailPuntosAbonados', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, sku, puntos, puntosInicio, puntosFinal }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.error || 'Error al enviar el correo de confirmación.');
      }
    } catch (error) {
      console.error('Error en el proceso de registro:', error);
    }
  };

  const handleAceptarAbono = async () => {
    try {
      setIsSubmitting(true);
      const sku = producto.sku;
      const response = await fetch('/.netlify/functions/abonarPuntos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sku: sku,
          puntos: puntos,
          usuario: userData.codigo_referido
        }),
      });

      const result = await response.json();

      if (response.status === 200) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSubmitting(false);
          setShowCheckmark(true);
          setTimeout(() => {
            setModalAbierto(false);
            setShowCheckmark(false);
            setIsSuccess(false);
            setPuntos(minimoPuntos);
          }, 3000);
        }, 1000);
        await sendEmailPuntosAbonados(userData.email, sku, puntos, result.puntosInicio, result.puntosFinal);
      } else {
        console.error('Error inserting ticket:', result.message);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error handling abono de puntos:', error.message);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showCheckmark && checkmarkRef.current) {
      const timer = setTimeout(() => {
        checkmarkRef.current.classList.add('filled');
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [showCheckmark]);

  const handleCerrarModal = () => {
    setModalAbierto(false);
  };

  const agregarAlCarrito = (ticket) => {
    const productoDescripcion = `Ticket ${ticket.ticket} por ${ticket.puntos} puntos c/u`;
    const fechaActual = new Date();
    const fecha_carrito = fechaActual.toLocaleDateString();
    const hora_carrito = fechaActual.toLocaleTimeString();

    agregarItem({
      ...ticket,
      producto: productoDescripcion,
      cantidad: 1,
      tipo: 'ticket',
      fecha_carrito,
      hora_carrito
    });
  };

  const startIncrement = () => {
    incrementPuntos();
    let currentSpeed = speed;
    const id = setInterval(() => {
      incrementPuntos();
      currentSpeed = Math.max(10, currentSpeed - 10);
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(incrementPuntos, currentSpeed);
    }, currentSpeed);
    intervalRef.current = id;

    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(incrementPuntos, 50);
    }, 3000);

    speedTimeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        for (let i = 0; i < 10; i++) {
          incrementPuntos();
        }
      }, 100);
    }, 6000);
  };

  const startDecrement = () => {
    decrementPuntos();
    let currentSpeed = speed;
    const id = setInterval(() => {
      decrementPuntos();
      currentSpeed = Math.max(10, currentSpeed - 10);
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(decrementPuntos, currentSpeed);
    }, currentSpeed);
    intervalRef.current = id;

    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(decrementPuntos, 50);
    }, 3000);

    speedTimeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        for (let i = 0; i < 10; i++) {
          decrementPuntos();
        }
      }, 100);
    }, 6000);
  };

  const handleMouseUp = () => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
    clearTimeout(speedTimeoutRef.current);
    setSpeed(100);
  };

  const progressValue = puntosAportados;
  const progressMax = producto ? producto.puntos : 0;

  if (isLoading) {
    return <ParticipaProductSkeleton />;
  }

  if (!producto) {
    return <p>No se encontró el producto.</p>;
  }

  return (
    <div className="participa-container mt-10">
      {modalAbierto && (
        <dialog id="abonarModal" className="modal modal-bottom sm:modal-middle" open>
          <div className="modal-box border-mm border-2">
            <div className="modal-action">
              <p className="w-full">¿Estás seguro de que deseas abonar <span className='color-mm'>{puntos} puntos</span> a este producto?</p>
            </div>
            <div className="modal-action flex justify-center mt-4">
              {!isSubmitting && !isSuccess && (
                <>
                  <button type="button" className="mr-20" onClick={handleCerrarModal}>no, cancelar</button>
                  <button type="button" className="btn btn-primary" onClick={handleAceptarAbono}>Si estoy seguro</button>
                </>
              )}
              {isSubmitting && (
                <div className="loading-container flex flex-col items-center">
                  <span className="loading loading-spinner loading-lg text-mm mt-2"></span>
                </div>
              )}
              {!isSubmitting && showCheckmark && (
                <svg ref={checkmarkRef} className="checkmark" xmlns="http://www.w3.org/2000/svg" height="96px" viewBox="0 -960 960 960" width="96px">
                  <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                </svg>
              )}
            </div>
          </div>
        </dialog>
      )}

      <h1 ref={titleRef} className={`title-mm ${titleInView ? 'animate-fade-in' : ''}`}>{producto.producto}</h1>
      <div ref={imageRef} className={`imagen-grande ${imageInView ? 'animate-slide-in-left' : ''}`}>
        {imagenPrincipal && <img src={imagenPrincipal} alt="Imagen principal" className="imagen-principal" />}
      </div>
      <div className="imagenes-pequenas">
        {[producto.imagen_1, producto.imagen_2, producto.imagen_3, producto.imagen_4, producto.imagen_5, producto.imagen_6].map((imagen, index) => (
          imagen && (
            <img
              key={index}
              src={imagen}
              alt={`Imagen ${index + 1}`}
              className="imagen-pequena"
              onMouseEnter={() => {
                handleHoverImagen(imagen);
                setNombreImagenHover(producto.producto);
              }}
            />
          )
        ))}
      </div>
      <div ref={infoRef} className={`info-producto ${infoInView ? 'animate-fade-in' : ''}`}>
        <h2>{nombreImagenHover}</h2>
        <p>SKU: {producto.sku}</p>
        <h2 className='mt-4'>{puntosAportados.toLocaleString()} de {progressMax.toLocaleString()} puntos</h2>
      </div>
      <div ref={progressRef} className={`progress-container ${progressInView ? 'animate-slide-in-right' : ''}`}>
        <progress className="progress progress-mm w-56" value={progressValue} max={progressMax}></progress>
      </div>

      {mostrarFechaSorteo ? (
        <div className="fecha-sorteo">
          ¡El sorteo será en 3 días más!
        </div>
      ) : (
        <div className="abonar-puntos-container">
          <div className="puntos-input">
            <div
              className='flecha-svg'
              onMouseDown={startDecrement}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              ¼
            </div>
            <input
              type="number"
              value={puntos}
              onChange={handlePuntosChange}
              onBlur={handlePuntosBlur}
              placeholder={`Entre ${minimoPuntos} y ${producto ? producto.puntos : 0}`}
            />
            <div
              className='flecha-svg'
              onMouseDown={startIncrement}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              ½
            </div>
          </div>
          <button onClick={handleAbonarPuntos} className={`btn btn-accent ${totalPuntos >= minimoPuntos ? 'btn-enabled' : 'btn-disabled'}`} disabled={totalPuntos < minimoPuntos}>{totalPuntos >= minimoPuntos ? 'Abonar Puntos' : 'Puntos Insuficientes'}</button>
          <br /><br /><br />
        </div>
      )}


      {(sorteosCerrados.length > 0 || sorteosFinalizados.length > 0) && (
        <div className="sorteos-container">
          {sorteosCerrados.length > 0 && (
            <div className="sorteos-cerrados">
              <h2>Sorteos Cerrados</h2>
              {sorteosCerrados.map((sorteo) => (
                <Link to={`/productos/detalle-producto/${producto.sku}/sorteo/${sorteo.key}`} className="producto-link">
                  <div key={sorteo.key}>
                    <h3>Sorteo N° {sorteo.key}</h3>
                    <p>Fecha de cierre: {format(new Date(sorteo.fecha_termino), 'dd MMM yyyy', { locale: es })}</p>
                    <p>Fecha de sorteo: {format(new Date(sorteo.fecha_sorteo), 'dd MMM yyyy', { locale: es })}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
          {sorteosFinalizados.length > 0 && (
            <div className="sorteos-finalizados">
              <h2>Sorteos Finalizados</h2>
              {sorteosFinalizados.map((sorteo) => (
                <div key={sorteo.id}>
                  <h3>Sorteo {sorteo.id}</h3>
                  <p>Fecha de termino: {sorteo.fecha_termino}</p>
                  <p>Fecha de sorteo: {sorteo.fecha_sorteo}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

        <div className="comprar-seccion mt-8 background-header p-10 border-mm rounded-lg">
          <h3 className="text-lg font-bold mb-2 color-mm">¿Sabías que puedes comprar este producto?</h3>
          <p className="mb-4">Compra y recibelo en días en la comodidad de tu hogar, enviamos a todo Chile</p>
          <button 
            className="btn btn-secondary"
            onClick={() => window.location.href = `/productos/detalle-producto/${producto.sku}/comprar`}
          >
            Comprar ahora
          </button>
        </div>

        <div ref={descripcionRef} className={`producto-descripcion mt-20 ${descripcionInView ? 'animate-fade-in' : ''}`}>
        <div 
          className="descripcion-contenido"
            dangerouslySetInnerHTML={{ 
              __html: DOMPurify.sanitize(procesarDescripcion(producto.descripcion)) 
            }} 
          />
        </div>

      <h2 ref={ticketsRef} className={`title-mm ${ticketsInView ? 'animate-fade-in' : ''}`}>Adquiere tus puntos</h2>
      <div className={`tickets-container ${ticketsInView ? 'animate-fade-in-up' : ''}`}>
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className="card card-ticket bg-header w-64 shadow-xl"
            onMouseEnter={(e) => {
              const img = e.currentTarget.querySelector('img');
              if (img) img.src = ticket.imagen_1;
            }}
            onMouseLeave={(e) => {
              const img = e.currentTarget.querySelector('img');
              if (img) img.src = ticket.imagen_2;
            }}
          >
            <figure className="px-1 pt-1">
              <img
                src={ticket.imagen_2}
                alt={ticket.ticket}
                className="rounded-xl"
              />
            </figure>
            <button className="buy-button" onClick={() => agregarAlCarrito(ticket)}>AGREGAR AL CARRITO</button>
            <div className="card-body items-center text-center">
              <h2 className="card-title" style={{ color: `${ticket.hex}`, textTransform: 'uppercase', textShadow: '1px 1px 2px white' }}>
                {ticket.ticket}
              </h2>
              <p>Precio: $ {Number(ticket.precio).toLocaleString('es-ES')}</p>
              <p><span className='color-mm'>{Number(ticket.puntos).toLocaleString('es-ES')}</span> puntos</p>
            </div>
          </div>
        ))}
      </div>

      <div ref={similaresRef} className={`productos-similares-container mt-40 ${similaresInView ? 'animate-fade-in-up' : ''}`}>
        <ProductosSimilares skuActual={sku} />
      </div>
        <br /><br /><br />
    </div>
  );
};

export default Participa;
