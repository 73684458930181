import { supabase } from '../lib/supabaseClient';

export const loginUser = async (credentials) => {
  const { user, error } = await supabase.auth.signIn({
    email: credentials.email,
    password: credentials.password,
  });

  if (error) throw error;

  // Obtener información adicional del usuario si es necesario
  const { data: userData, error: userError } = await supabase
    .from('usuarios')
    .select('*')
    .eq('id', user.id)
    .single();

  if (userError) throw userError;

  return { ...user, ...userData };
};

export const getCurrentUser = async () => {
  const user = supabase.auth.user();
  if (!user) return null;

  // Obtener información adicional del usuario si es necesario
  const { data: userData, error } = await supabase
    .from('usuarios')
    .select('*')
    .eq('id', user.id)
    .single();

  if (error) throw error;

  return { ...user, ...userData };
};