import React, { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabaseClient';
import { Link } from 'react-router-dom';

const VentasAdmin = () => {
  const [ventas, setVentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchVentas = async () => {
    setLoading(true);
    setError(null);

    try {
      // Fetch all sales from the 'ventas' table
      const { data: ventasData, error: ventasError } = await supabase
        .from('ventas')
        .select('*, productos_compras (total, estado), tickets_compras (total, estado)')
        .order('fecha');

      if (ventasError) {
        setError(ventasError.message);
        setLoading(false);
        return;
      }

      // Process the data to calculate totals
      const ventasList = ventasData.map(venta => {
        const productosData = venta.productos_compras || [];
        const ticketsData = venta.tickets_compras || [];

        // Calculate totals
        const productosTotal = productosData.reduce((sum, item) => sum + item.total, 0);
        const ticketsTotal = ticketsData.reduce((sum, item) => sum + item.total, 0);

        // Determine the payment status
        const estadoPago = ticketsData.length > 0 ? ticketsData[0].estado : 'No registrado';

        return {
          id: venta.id,
          fecha_venta: new Date(venta.fecha).toLocaleDateString(),
          codigo: venta.codigo,
          usuario: venta.usuario,
          productos: productosTotal,
          puntos: ticketsTotal,
          total: productosTotal + ticketsTotal,
          estado_pago: estadoPago
        };
      });

      setVentas(ventasList);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVentas();

    const ventasChannel = supabase
      .channel('ventas')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'ventas' }, (payload) => {
        console.log('Change received in ventas!', payload);
        fetchVentas(); // Fetch ventas data on change
      })
      .subscribe();

    const productosComprasChannel = supabase
      .channel('productos_compras')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'productos_compras' }, (payload) => {
        console.log('Change received in productos_compras!', payload);
        fetchVentas(); // Fetch ventas data on change
      })
      .subscribe();

    const ticketsComprasChannel = supabase
      .channel('tickets_compras')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'tickets_compras' }, (payload) => {
        console.log('Change received in tickets_compras!', payload);
        fetchVentas(); // Fetch ventas data on change
      })
      .subscribe();

    // Clean up subscriptions on unmount
    return () => {
      supabase.removeChannel(ventasChannel);
      supabase.removeChannel(productosComprasChannel);
      supabase.removeChannel(ticketsComprasChannel);
    };
  }, []);

  const handlePagoRecibido = async (codigo) => {
    try {
      const response = await fetch('/.netlify/functions/pagoRecibido', {
        method: 'POST',
        body: JSON.stringify({ codigo }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Payment status updated successfully:', result);
        // Refresh the sales data or update the UI to reflect the changes
        fetchVentas(); // Fetch ventas data again to reflect changes

        
            const response = await fetch('/.netlify/functions/sendEmailPagoRecibido', {
              method: 'POST',
              body: JSON.stringify({ codigo }),
            });
        
            const result_email = await response.json();
        
            if (response.ok) {
              console.log('emails enviados:', result_email);
              // Refresh the sales data or update the UI to reflect the changes
              fetchVentas(); // Fetch ventas data again to reflect changes
            } else {
              console.error('error al enviar emails:', result_email);
            }

        

      } else {
        console.error('Error updating payment status:', result);
      }
    } catch (error) {
      console.error('Error making the request:', error);
    }
  };

  if (loading) return <p>Cargando ventas...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='container-generico'>
      <h1 className='title-mm'>Ventas</h1>
      {ventas.length === 0 ? (
        <>
          <p>No hay ventas registradas. <Link to="/productos" className="enlace-mm">Ver productos aquí</Link></p>
          <br /><br /><br /><br /><br /><br />
        </>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Fecha de Venta</th>
              <th>Código</th>
              <th>Usuario</th>
              <th>$ Productos</th>
              <th>$ Puntos</th>
              <th>$ Total</th>
              <th>Estado de Pago</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {ventas.map(venta => (
              <tr key={venta.id}>
                <td>{venta.id}</td>
                <td>{venta.fecha_venta}</td>
                <td>{venta.codigo}</td>
                <td>{venta.usuario}</td>
                <td>{venta.productos}</td>
                <td>{venta.puntos}</td>
                <td>{venta.total}</td>
                <td>{venta.estado_pago}</td>
                <td>
                  {venta.estado_pago === 'Validando Pago' && (
                    <button 
                      onClick={() => handlePagoRecibido(venta.codigo)}
                      className="btn btn-success"
                    >
                      Pago recibido
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  );
};

export default VentasAdmin;
