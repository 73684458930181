import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import './MisPedidos.css';
import { Link } from 'react-router-dom'; 
import MisPedidosSkeleton from './skeleton/MisPedidosSkeleton'; // Corregimos la importación

const MisPedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPedidos = async () => {
      setLoading(true);
      setError(null);

      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        console.log("Usuario no logueado");
        setLoading(false);
        return;
      }

      console.log('User data:', user);
      console.log('User data ID:', user.id);

      if (!user.id) {
        console.error('El ID del usuario no está definido.');
        setLoading(false);
        return;
      }

      try {
        // Consultar datos del usuario actual desde la tabla 'usuarios'
        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (userError) {
          console.error('Error al obtener datos del usuario:', userError.message);
          throw userError;
        }

        if (!userData) {
          throw new Error('Usuario no encontrado en la tabla de usuarios');
        }

        // Consultar movimientos de productos_compras
        const { data: productosData, error: productosError } = await supabase
          .from('productos_compras')
          .select(`
            id,
            fecha_compra,
            cantidad,
            precio,
            total,
            estado,
            puntos,
            productos_estados (puntos_validos),
            productos (
              producto,
              sku,
              imagen_1
            )
          `)
          .eq('usuario', userData.codigo_referido);

        if (productosError) {
          setError(productosError.message);
          setLoading(false);
          return;
        }

        const productosPedidos = productosData.map(pedido => ({
          id: pedido.id,
          fecha_compra: pedido.fecha_compra,
          cantidad: pedido.cantidad,
          precio: new Intl.NumberFormat('es-CL').format(pedido.precio),
          total: new Intl.NumberFormat('es-CL').format(pedido.total),
          estado: pedido.estado,
          puntos: pedido.puntos,
          producto: pedido.productos?.producto || 'No disponible',
          sku: pedido.productos?.sku || 'No disponible',
          imagen: pedido.productos?.imagen_1 || '',
          puntos_regalo: pedido.productos_estados?.puntos_validos ? pedido.puntos : 0
        }));

        setPedidos(productosPedidos);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchPedidos();
  }, []);

  // Modificamos la parte donde se muestra el loading
  if (loading) return <MisPedidosSkeleton />; // Usamos el componente skeleton correcto
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='container-generico min-h-screen'>
      <h1 className='title-mm'>Mis Pedidos</h1>
      {pedidos.length === 0 ? (
        <>
          <p>No tienes pedidos. <Link to="/productos" className="enlace-mm">Ver productos aquí</Link></p>
        </>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Producto</th>
              <th>SKU</th>
              <th>Fecha de Compra</th>
              <th>Cantidad</th>
              <th>Precio</th>
              <th>Total</th>
              <th>Estado</th>
              <th>Puntos</th>
            </tr>
          </thead>
          <tbody>
            {pedidos.map(pedido => (
              <tr key={pedido.id}>
                <td><img src={pedido.imagen} alt={pedido.producto} className='product-image' /></td>
                <td>{pedido.producto}</td>
                <td>{pedido.sku}</td>
                <td>{new Date(pedido.fecha_compra).toLocaleDateString()}</td>
                <td>{pedido.cantidad}</td>
                <td>{pedido.precio}</td>
                <td>{pedido.total}</td>
                <td>{pedido.estado}</td>
                <td>{pedido.puntos}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MisPedidos;