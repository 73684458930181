import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { PuntosContext } from './context/PuntosContext';
import './Sorteo.css';




// const useOnlineStatus = (userId) => {
//   useEffect(() => {
//     const updateOnlineStatus = async (status) => {
//       await supabase
//         .from('usuarios')
//         .update({ estado_online: status })
//         .eq('user_id', userId);
//     };

//     // Set user online
//     updateOnlineStatus(true);

//     // Set user offline on cleanup
//     return () => {
//       updateOnlineStatus(false);
//     };
//   }, [userId]);
// };

const Sorteo = () => {
  const { key } = useParams();
  const { userData } = useContext(PuntosContext);
  const [sorteo, setSorteo] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [ganadores, setGanadores] = useState(null);
  const [hasInput, setHasInput] = useState(false);
  const [inputCount, setInputCount] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [adminMessage, setAdminMessage] = useState('');

  // useOnlineStatus(userData?.user_id);

  useEffect(() => {
    const fetchSorteo = async () => {
      const { data } = await supabase
        .from('sorteos')
        .select('*')
        .eq('key', key)
        .single();

      setSorteo(data);
    };

    const fetchTickets = async () => {
      const { data } = await supabase
        .from('tickets_participantes')
        .select('*')
        .eq('sorteo', key)
        .order('puntos_inicio', { ascending: true });

      setTickets(data);

      const userReferidos = data.map(ticket => ticket.usuario);
      const { data: users } = await supabase
        .from('usuarios')
        .select('codigo_referido, nombre')
        .in('codigo_referido', userReferidos);

      const userNamesMap = users.reduce((acc, user) => {
        acc[user.codigo_referido] = user.nombre;
        return acc;
      }, {});

      setUserNames(userNamesMap);
    };

    const checkUserInput = async () => {
      const { data } = await supabase
        .from('sorteos_input')
        .select('*')
        .eq('sorteo', key)
        .eq('usuario', userData?.codigo_referido);

      setHasInput(data.length > 0);
    };

    const fetchInputCount = async () => {
      const { data } = await supabase
        .from('sorteos_input')
        .select('*')
        .eq('sorteo', key);

      setInputCount(data.length);
    };

    const fetchAdminMessage = async () => {
      const { data } = await supabase
        .from('sorteos')
        .select('mensaje_admin')
        .eq('key', key)
        .single();

      setAdminMessage(data?.mensaje_admin || '');
    };

    fetchSorteo();
    fetchTickets();
    checkUserInput();
    fetchInputCount();
    fetchAdminMessage();

    // Subscribe to real-time updates for sorteo
    const sorteoChannel = supabase
      .channel('sorteo')
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'sorteos' }, (payload) => {
        setSorteo(payload.new);
        setGanadores({
          ganador_producto: payload.new.ganador_producto,
          ganador_puntos: payload.new.ganador_puntos,
        });
        setAdminMessage(payload.new.mensaje_admin || '');
      })
      .subscribe();

    // Subscribe to real-time updates for sorteo input count
    const inputChannel = supabase
      .channel('input')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'sorteos_input' }, () => {
        fetchInputCount();
      })
      .subscribe();

    return () => {
      // Cleanup function if needed
      supabase.removeChannel(sorteoChannel);
      supabase.removeChannel(inputChannel);
    };
  }, [key, userData]);

  const iniciarSorteo = async () => {
    if (userData?.role !== 'admin') return;

    setIsGenerating(true);

    const response = await fetch('/.netlify/functions/iniciarSorteo', {
      method: 'POST',
      body: JSON.stringify({ key }),
    });

    const result = await response.json();

    if (response.ok) {
      console.log('Sorteo started successfully:', result);
    } else {
      console.log('Error starting sorteo:', result.message, result.error);
    }

    setGanadores(result.ganadores);

    // Update the sorteo status in the database
    await supabase
      .from('sorteos')
      .update({ estado: 'Finalizado' })
      .eq('key', key);

    setIsGenerating(false);
  };

  const comenzarSorteo = async () => {
    if (hasInput) {
      alert('Ya has ingresado un input para este sorteo.');
      return;
    }

    const input = Math.floor(Math.random() * 100) + 1;
    const { data, error } = await supabase
      .from('sorteos_input')
      .insert({
        usuario: userData.codigo_referido,
        sorteo: key,
        input,
      });

    if (error) {
      console.error('Error al insertar en sorteos_input:', error.message);
    } else {
      console.log('Registro insertado en sorteos_input:', data);
      setHasInput(true);
      setInputCount(prevCount => prevCount + 1);
    }
  };

  const enviarMensajeAdmin = async () => {
    if (!adminMessage) return; // No enviar mensajes vacíos

    const { error } = await supabase
      .from('sorteos')
      .update({ mensaje_admin: adminMessage })
      .eq('key', key);

    if (error) {
      console.error('Error al enviar mensaje del administrador:', error.message);
    }
  };

  const borrarMensajeAdmin = async () => {
    const { error } = await supabase
      .from('sorteos')
      .update({ mensaje_admin: null })
      .eq('key', key);

    if (error) {
      console.error('Error al borrar mensaje del administrador:', error.message);
    } else {
      setAdminMessage('');
      setGanadores(null); // Limpiar también ganadores al borrar el mensaje del administrador
    }
  };

  const handleAdminMessageChange = (event) => {
    setAdminMessage(event.target.value);
  };

  const userHasPoints = tickets.some(ticket => ticket.usuario === userData?.codigo_referido);

  return (
    <div className="sorteo-container">
      {sorteo && (
        <>
          <h1>Sorteo: {sorteo.key}</h1>
          <p>Fecha de inicio: {new Date(sorteo.fecha_inicio).toLocaleDateString()}</p>
          <p>Fecha del sorteo: {new Date(sorteo.fecha_sorteo).toLocaleDateString()}</p>
          <p>Estado: {sorteo.estado}</p>
          <br /><br />
          {userData?.role === 'admin' && sorteo.estado === 'Cerrado' && inputCount >= 5 && !isGenerating ? (
            <button onClick={iniciarSorteo}>Iniciar Sorteo</button>
          ) : (
            <p>
              {sorteo.estado === 'Finalizado'
                ? `Ganador del Producto: N° ${sorteo.numero_producto}, Ganador de los Puntos: ${sorteo.numero_puntos}`
                : isGenerating
                  ? 'Generando ganadores...'
                  : inputCount < 5
                    ? `Faltan ${5 - inputCount} participantes para iniciar el sorteo.`
                    : adminMessage
                      ? adminMessage
                      : 'Pronto se realizará el sorteo. ¡Atentos!'
              }
            </p>
          )}
          {userData?.role !== 'admin' && userHasPoints && !hasInput ? (
            <button onClick={comenzarSorteo}>Comenzar Sorteo</button>
          ) : (
            null
          )}
          <br /><br /><br />
          {ganadores && (ganadores.ganador_producto || ganadores.ganador_puntos) && (
            <div className="ganadores-card">
              <h2>Ganadores</h2>
              {ganadores.ganador_producto && <p><strong>Ganador del Producto:</strong> {ganadores.ganador_producto}</p>}
              {ganadores.ganador_puntos && <p><strong>Ganador de los Puntos:</strong> {ganadores.ganador_puntos}</p>}
            </div>
          )}

          <h2>Participantes</h2>
          <div className="tickets-container">
            <table>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Usuario</th>
                  <th>puntos</th>
                  <th>Inicio</th>
                  <th>Final</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <td>{`${ticket.usuario.slice(0, 3)}****${ticket.usuario.slice(-1)}`}</td>
                    <td>{userNames[ticket.usuario]}</td>
                    <td>{ticket.puntos_aportados}</td>
                    <td>{ticket.puntos_inicio}</td>
                    <td>{ticket.puntos_final}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br /><br />
          {userData?.role === 'admin' && (
            <>
              <h2>Editar Mensaje para Usuarios</h2>
              <textarea
                rows="4"
                cols="50"
                value={adminMessage}
                onChange={handleAdminMessageChange}
              />
              <button onClick={enviarMensajeAdmin}>Enviar Mensaje</button>
              <button onClick={borrarMensajeAdmin}>Borrar Mensaje</button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Sorteo;
