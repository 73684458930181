// src/components/AdminPanel.js
import React, { useContext } from 'react';
import { PuntosContext } from '../context/PuntosContext';
import { Link, useLocation } from 'react-router-dom';
import './MenuAdmin.css';

const AdminPanel = () => {
  const { isAdmin } = useContext(PuntosContext);
  const location = useLocation();
  

  if (!isAdmin) {
    return null;
  }



  return (
    <>
      <div className="floating-button">
        <label htmlFor="admin-drawer" className="drawer-button">
          <span className="material-symbols-outlined">admin_panel_settings</span>
        </label>
      </div>
      <div className="drawer">
        <input id="admin-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          {/* Aquí va el contenido principal de la página */}
        </div>
        <div className="drawer-side">
          <label htmlFor="admin-drawer" className="drawer-overlay"></label>
          <ul className="menu bg-header rounded-box z-50 w-52 p-2 shadow min-h-80 w-80 p-4 mt-25 ">
          <li><Link to="/admin/usuarios" className={`pb-0 ${location.pathname === '/admin/usuarios' ? 'active hover-underline' : 'hover-underline'}`}>Usuarios</Link></li>
          <li><Link to="/admin/ventas" className={`pb-0 ${location.pathname === '/admin/ventas' ? 'active hover-underline' : 'hover-underline'}`}>Ventas</Link></li>
          <li><Link to="/admin/puntos" className={`pb-0 ${location.pathname === '/admin/puntos' ? 'active hover-underline' : 'hover-underline'}`}>Puntos</Link></li>
          <li><Link to="/admin/sorteos" className={`pb-0 ${location.pathname === '/admin/sorteos' ? 'active hover-underline' : 'hover-underline'}`}>Sorteos</Link></li>
          <li><Link to="/admin/productos" className={`pb-0 ${location.pathname === '/admin/productos' ? 'active hover-underline' : 'hover-underline'}`}>Productos</Link></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
