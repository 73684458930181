import React, { useRef, useEffect } from 'react';

function ProductHeroCenter() {
  const heroRef = useRef(null);

  useEffect(() => {
    if (heroRef.current) {
      heroRef.current.style.backgroundAttachment = 'fixed';
    }
  }, []);

  return (
    <div
      className="hero background-mm relative pt-20 pb-20"
      ref={heroRef}
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/fondos/productos-para-tu-bebe.png)`, backgroundPosition: 'center', backgroundSize: 'cover' }}
    >
      <div className="hero-content text-center relative z-10">
        <div className="max-w-md mx-auto">
          <h2 className="text-5xl font-bold">¡Lo Mejor para tu Bebé!</h2>
          <p className="py-6">Descubre nuestra selección de productos diseñados para el confort y bienestar de tu pequeño. Desde ropita suave hasta juguetes educativos, tenemos todo lo que tu bebé necesita para crecer feliz y saludable.</p>
          <button className="btn btn-primary">Explora Ahora</button>

        </div>
      </div>
    </div>
  );
}

export default ProductHeroCenter;
