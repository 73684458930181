// src/components/PuntosContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { supabase } from '../../lib/supabaseClient';

export const PuntosContext = createContext();

export const PuntosProvider = ({ children }) => {
  const [totalPuntos, setTotalPuntos] = useState(0);
  const [userData, setUserData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [apuestas, setApuestas] = useState([]);

  const fetchPuntos = async (userId) => {
    if (!userId) {
      console.log("Usuario no logueado");
      return;
    }

    try {
      const { data: userData, error: userError } = await supabase
        .from('usuarios')
        .select('*')
        .eq('user_id', userId)
        .single();

      if (userError) throw userError;
      if (!userData) throw new Error('Usuario no encontrado en la tabla de usuarios');

      setUserData(userData);
      setIsAdmin(userData.role === 'admin');

      const { data: ticketsData, error: ticketsError } = await supabase
        .from('tickets_compras')
        .select('puntos, tickets_estados(puntos_validos)')
        .eq('usuario', userData.codigo_referido);

      if (ticketsError) throw ticketsError;

      const { data: productosData, error: productosError } = await supabase
        .from('productos_compras')
        .select('puntos, productos_estados(puntos_validos)')
        .eq('usuario', userData.codigo_referido);

      if (productosError) throw productosError;

      const { data: participacionesData, error: participacionesError } = await supabase
        .from('tickets_participantes')
        .select('puntos_aportados')
        .eq('usuario', userData.codigo_referido);

      if (participacionesError) throw participacionesError;

      const { data: apuestasPendientes, error: apuestasError } = await supabase
        .from('apuestas')
        .select('*')
        .eq('jugador', userData.codigo_referido)
        .eq('resultado', 'pendiente');

      if (apuestasError) throw apuestasError;

      setApuestas(apuestasPendientes);

      const { data: montoncitosData, error: montoncitosError } = await supabase
        .from('tickets_montoncitos')
        .select('puntos')
        .eq('jugador', userData.codigo_referido);

      if (montoncitosError) throw montoncitosError;

      const totalTicketsPuntos = ticketsData
        .filter(ticket => ticket.tickets_estados?.puntos_validos)
        .reduce((acc, ticket) => acc + (ticket.puntos || 0), 0);

      const totalProductosPuntos = productosData
        .filter(producto => producto.productos_estados?.puntos_validos)
        .reduce((acc, producto) => acc + (producto.puntos || 0), 0);

      const totalPuntosAportados = participacionesData
        .reduce((acc, participacion) => acc + (participacion.puntos_aportados || 0), 0);

      const totalApuestasPendientes = apuestasPendientes.reduce((acc, apuesta) => acc + apuesta.puntos_apostados, 0);

      const totalMontoncitosPuntos = montoncitosData
        .reduce((acc, montoncito) => acc + (montoncito.puntos || 0), 0);

      const nuevoTotalPuntos = totalTicketsPuntos + totalProductosPuntos - totalPuntosAportados - totalApuestasPendientes + totalMontoncitosPuntos;
      setTotalPuntos(nuevoTotalPuntos);

    } catch (error) {
      console.error('Error al obtener puntos:', error.message);
    }
  };

  const actualizarPuntos = useCallback((nuevosPuntos) => {
    setTotalPuntos(nuevosPuntos);
  }, []);

  const actualizarApuestas = useCallback((nuevasApuestas) => {
    setApuestas(nuevasApuestas);
  }, []);

  useEffect(() => {
    const setupSubscriptions = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        fetchPuntos(user.id);

        const ticketsChannel = supabase.channel('tickets_changes')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'tickets_compras' }, () => fetchPuntos(user.id))
          .subscribe();

        const productosChannel = supabase.channel('productos_changes')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'productos_compras' }, () => fetchPuntos(user.id))
          .subscribe();

        const participacionesChannel = supabase.channel('participaciones_changes')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'tickets_participantes' }, () => fetchPuntos(user.id))
          .subscribe();

        const apuestasChannel = supabase.channel('apuestas_changes')
          .on('postgres_changes', { event: '*', schema: 'public', table: 'apuestas' }, () => fetchPuntos(user.id))
          .subscribe();

        return () => {
          supabase.removeChannel(ticketsChannel);
          supabase.removeChannel(productosChannel);
          supabase.removeChannel(participacionesChannel);
          supabase.removeChannel(apuestasChannel);
        };
      }
    };

    const authListener = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setupSubscriptions();
      } else if (event === 'SIGNED_OUT') {
        setTotalPuntos(0);
        setUserData(null);
        setIsAdmin(false);
      }
    });

    setupSubscriptions();

    return () => {
      if (authListener) {
        authListener.data.subscription.unsubscribe();
      }
    };
  }, []);

  return (
    <PuntosContext.Provider value={{ totalPuntos, actualizarPuntos, userData, isAdmin, apuestas, actualizarApuestas }}>
      {children}
    </PuntosContext.Provider>
  );
};

export const usePuntos = () => React.useContext(PuntosContext);