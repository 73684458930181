import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AlertLogin.css';

const AlertLogin = ({ message, imageUrl, title, description, onClose }) => {
  const [closing, setClosing] = useState(false);
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/registrate');
  };

  const handleLogin = () => {
    navigate('/ingresar');
  };

  const handleClose = () => {
    setClosing(true);
    setTimeout(onClose, 500); // Espera a que la animación se complete antes de llamar a onClose
  };

  return (
    <dialog id="alertLoginModal" className="modal modal-bottom sm:modal-middle background-glass" open>
      <div className={`modal-box border-mm border-2 ${closing ? 'closing' : ''}`}>
        <button className="close-button" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="modal-action flex justify-center mt-4">
          <div className="card w-96">
            <div className="card-body">
              <p>{description}</p>
              <br />
              <div className="">
                <button className="btn btn-primary mr-20" onClick={handleRegister}>
                  Regístrate
                </button>
                <button className="btn btn-accent" onClick={handleLogin}>
                  Ingresar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default AlertLogin;
