import React from 'react';

function TicketsSkeleton() {
  return (
    <div className="ml-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="card w-64 bg-header shadow-xl ticket-card mt-10 animate-pulse">
          <div className="h-48 bg-gray-300 rounded-t-xl"></div>
          <div className="card-body items-center text-center">
            <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
          </div>
          <div className="card-actions justify-center mb-5">
            <div className="h-8 bg-gray-300 rounded w-32"></div>
          </div>
          <div className="ticket-info flex justify-center">
            <div className="ticket-precio">
              <div className="h-4 bg-gray-300 rounded w-16 mb-1"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TicketsSkeleton;