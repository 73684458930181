import React, { createContext, useState, useEffect } from 'react';

export const CarritoContext = createContext();

export const CarritoProvider = ({ children }) => {
  const [carrito, setCarrito] = useState([]);

  useEffect(() => {
    const carritoLocal = JSON.parse(localStorage.getItem('carrito')) || [];
    setCarrito(carritoLocal);
  }, []);

  const agregarItem = (item) => {
    setCarrito((prevCarrito) => {
      const itemExistente = prevCarrito.find(cartItem => cartItem.id === item.id && cartItem.tipo === item.tipo);
      let nuevoCarrito;
      if (itemExistente) {
        nuevoCarrito = prevCarrito.map(cartItem =>
          cartItem.id === item.id && cartItem.tipo === item.tipo ? { ...cartItem, cantidad: cartItem.cantidad + item.cantidad } : cartItem
        );
      } else {
        nuevoCarrito = [...prevCarrito, item];
      }
      localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
      return nuevoCarrito;
    });
  };

  return (
    <CarritoContext.Provider value={{ carrito, setCarrito, agregarItem }}>
      {children}
    </CarritoContext.Provider>
  );
};
