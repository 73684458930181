import React, { useEffect, useState, useRef } from 'react';

function ProductHeroLeft() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const carouselRef = useRef(null);

  const images = [
    "/productos/parrilla/parrilla-brasero.png",
    "/productos/parrilla/parrilla-tambor-con-ruedas.png",
    "/productos/parrilla/parrilla-con-ruedas-plegable.png",
    "/productos/parrilla/parrilla-simple.png",
    "/productos/parrilla/brasero.png",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isDragging, images.length]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="hero min-h-screen background-mm">
      <div className="hero-content flex-col lg:flex-row">
        <div
          ref={carouselRef}
          className="w-64 carousel rounded-box"
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          {images.map((image, index) => (
            <div key={index} className={`carousel-item w-full ${index === currentIndex ? '' : 'hidden'}`}>
              <img src={image} className="w-full" alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </div>
        <div>
          <h2 className="text-5xl font-bold">¡La Mejor Parrilla para tus Asados!</h2>
          <p className="py-6">Transforma tus reuniones en momentos inolvidables con nuestras parrillas de alta calidad. Diseñadas para ofrecer el mejor sabor y la máxima durabilidad. ¡Haz de cada asado una experiencia única!</p>
          <button className="btn btn-primary">Compra Ahora</button>
        </div>
      </div>
    </div>
  );
}

export default ProductHeroLeft;
