import React, { useState, useEffect } from 'react';
import './ProductList.css';

function ProductList() {
  const products = [
    { 
      id: 1, 
      name: 'Kit De Herramientas Inalámbrico X4', 
      price: '$150',
      description: 'Kit de herramientas completo para todas tus necesidades de carpintería.',
      images: [
        '/productos/herramientas/MM0010-2-img1.png',
        '/productos/herramientas/MM0010-2-img2.png',
        '/productos/herramientas/MM0010-2-img3.png',
        '/productos/herramientas/MM0010-2-img4.png'
      ]
    },
    { 
      id: 2, 
      name: 'Esmeril Angular Recargable Eléctrico 2 B', 
      price: '$110',
      description: 'Esmeril angular de alta potencia para trabajos profesionales.',
      images: [
        '/productos/herramientas/EM-Esmeril-angular-img1.png',
        '/productos/herramientas/EM-Esmeril-angular-img2.png',
        '/productos/herramientas/EM-Esmeril-angular-img3.png',
        '/productos/herramientas/EM-Esmeril-angular-img4.png'
      ]
    },
    { 
      id: 3, 
      name: 'Taladro Inalámbrico Watson 36v + 2 Bat', 
      price: '$120',
      description: 'Taladro inalámbrico con batería de larga duración para uso intensivo.',
      images: [
        '/productos/herramientas/kit-taladro-img1.png',
        '/productos/herramientas/kit-taladro-img2.png',
        '/productos/herramientas/kit-taladro-img3.png',
        '/productos/herramientas/kit-taladro-img4.png'
      ]
    }
  ];

  const [imageIndex, setImageIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex(prevIndex => (prevIndex + 1) % 4);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="mt-16 mb-16 background-mm">
      <h2 className="text-2xl font-bold my-16">HERRAMIENTAS</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-32 background-mm">
        {products.map((product, index) => (
          <a key={product.id} href={`/product/${product.id}`} className="relative overflow-hidden">
            <div 
              className="card-wrapper"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="card w-96 background-mm shadow-xl">
                <figure style={{ position: 'relative' }}>
                  <img src={product.images[imageIndex]} alt={product.name} />
                  <div 
                    className="shine-overlay absolute left-0 top-0 w-full h-full bg-gradient-to-br from-transparent via-white to-transparent"
                    style={{ 
                      animation: hoveredIndex === index ? 'moveShine 0.5s linear forwards' : 'none',
                      opacity: hoveredIndex === index ? 1 : 0,
                    }}
                  ></div>
                </figure>
                <div className="card-body">
                  <h2 className="card-title">
                    {product.name}
                    <div className="badge badge-secondary">NEW</div>
                  </h2>
                  <div className="card-actions justify-end">
                    <div className="badge badge-outline">Herramientas</div>
                  </div>
                  <p>{product.description}</p>
                  <div className="price">{product.price}</div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default ProductList;
