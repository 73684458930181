import React, { useState } from 'react';
import './ProductMove.css';

const ProductMove = ({ id, name, description, images }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`product-move-card-wrapper ${hovered ? 'hovered' : ''}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="product-move-card">
        <figure className="product-move-flip-container">
          <img
            id={`product-img-${id}-1`}
            src={hovered ? images[1] : images[0]}
            alt={name}
            className="product-move-img"
          />
        </figure>
        <div className="product-move-card-body">
          <h2 className="product-move-card-title">{name}</h2>
          <p>{description}</p>
          <div className="product-move-card-actions justify-end">
            <button className="btn btn-primary">Learn now!</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductMoveList = () => {
  const [isPaused, setIsPaused] = useState(false);

  const products = [
    { id: 1, name: 'Product 1', description: 'Description of product 1', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 2, name: 'Product 2', description: 'Description of product 2', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 3, name: 'Product 3', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 4, name: 'Product 4', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 5, name: 'Product 5', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 6, name: 'Product 6', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 7, name: 'Product 7', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 8, name: 'Product 8', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 9, name: 'Product 9', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 10, name: 'Product 10', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
    { id: 11, name: 'Product 11', description: 'Description of product 3', images: ['/productos/zapato1.png', '/productos/zapato2.png'] },
  ];

  return (
    <div
      className={`product-move-carousel ${isPaused ? 'paused' : ''}`}
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <div className="product-move-track mt-10">
        {products.map((product, index) => (
          <div className="product-move-carousel-item" key={index}>
            <ProductMove {...product} />
          </div>
        ))}
        {products.map((product, index) => (
          <div className="product-move-carousel-item" key={`clone-${index}`}>
            <ProductMove {...product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductMoveList;
