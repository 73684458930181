import React from 'react';

function Footer() {
  return (
    <footer className="footer p-10 background-header">
      <nav>
        <h6 className="footer-title">Productos</h6> 
        {/* <a className="link link-hover">Branding</a>
        <a className="link link-hover">Design</a>
        <a className="link link-hover">Marketing</a>
        <a className="link link-hover">Advertisement</a> */}
      </nav> 
      <nav>
        <h6 className="footer-title">Categorias</h6> 
        {/* <a className="link link-hover">Tecnología</a>
        <a className="link link-hover">Herramientas</a>
        <a className="link link-hover">Gamer</a>
        <a className="link link-hover">Cocina</a> */}
      </nav> 
      <nav>
        <h6 className="footer-title">Legal</h6> 
        {/* <a className="link link-hover">Terms of use</a>
        <a className="link link-hover">Privacy policy</a>
        <a className="link link-hover">Cookie policy</a> */}
      </nav> 
      <form>
        <h6 className="footer-title">Novedades y Ofertas</h6> 
        {/* <fieldset className="form-control w-80">
          <label className="label">
            <span className="label-text">Enviamos un Email Semanal</span>
          </label> 
          <div className="join">
            <input type="text" placeholder="tu-mejor-email@gmail.com" className="input input-bordered join-item" /> 
            <button className="btn btn-primary join-item">Suscribir</button>
          </div>
        </fieldset> */}
      </form>
    </footer>
  );
}

export default Footer;
