import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { Helmet } from 'react-helmet';
import './RegisterForm.css';

const RegisterForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();

  const getCodigoReferidoFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('key_referido');
  };

  const generateUniqueReferralCode = async () => {
    let isUnique = false;
    let referralCode = '';

    while (!isUnique) {
      referralCode = Math.random().toString(36).substring(2, 10).toUpperCase();
      const { data } = await supabase
        .from('usuarios')
        .select('codigo_referido')
        .eq('codigo_referido', referralCode);

      if (data.length === 0) {
        isUnique = true;
      }
    }

    return referralCode;
  };

  const checkAndCreateUser = async (user) => {
    if (!user) {
      return null;
    }

    try {
      const { error: userError } = await supabase
        .from('usuarios')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (userError && userError.code === 'PGRST116') {
        const referralCode = await generateUniqueReferralCode();
        const { error: insertError } = await supabase
          .from('usuarios')
          .insert({
            user_id: user.id,
            codigo_referido: referralCode,
            email: user.email,
            role: 'usuario',
          });

        if (insertError) {
          setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
          return null;
        }
        return referralCode;
      } else if (userError) {
        setError('Error al verificar usuario. Por favor, intenta de nuevo más tarde.');
        return null;
      }
    } catch (error) {
      setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
      return null;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    setError('');
    setMessage('');
    setIsSuccess(false);

    const codigoReferido = getCodigoReferidoFromUrl();

    try {
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      }, {
        redirectTo: 'https://matimarket.com/carrito',
      });

      if (signUpError) {
        setError(signUpError.message);
        setIsSubmitting(false);
        return;
      }

      const user = data.user;
      const referralCode = await checkAndCreateUser(user);

      if (!referralCode) {
        setIsSubmitting(false);
        return;
      }

      if (codigoReferido) {
        const { error: referidoError } = await supabase
          .from('referidos')
          .insert({
            referente_codigo: codigoReferido,
            referido_codigo: referralCode,
          });

        if (referidoError) {
          setError('Error al registrar el referido. Por favor, intenta de nuevo más tarde.');
          setIsSubmitting(false);
          return;
        }
      }

      setIsSuccess(true);
      setMessage('Revisa tu correo para confirmar tu cuenta.');
      setIsSubmitting(false);

    } catch (error) {
      setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const checkmark = document.querySelector('.checkmark');
      if (checkmark) {
        checkmark.addEventListener('animationend', () => {
          checkmark.classList.add('filled');
        });
      }
    }
  }, [isSuccess]);

  const isMobile = window.innerWidth <= 768;
  const backgroundImage = isMobile ? '/fondos/registrate/fondo1-cel-matimarket.com.webp' : '/fondos/registrate/fondo1-matimarket.com.webp';

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Helmet>
        <title>Registrate - MatiMarket</title>
        <meta name="description" content="Regístrate en MatiMarket y descubre productos innovadores y únicos. Únete a nuestra comunidad y disfruta de nuestras ofertas exclusivas." />
        <meta name="keywords" content="Registro, Crear cuenta, MatiMarket, Productos innovadores, Ofertas exclusivas" />
      </Helmet>
      <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 opacity-25 backdrop-blur-md"></div>
        <div className="relative z-10">
          <h1 className="title-mm color-mm font-bold mb-6 text-center">REGISTRATE</h1>
          <form onSubmit={handleRegister}>
            <div>
              <input
                id="email"
                type="email"
                className="appearance-none border rounded w-full mt-10 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-left"
                placeholder="Tú Mejor Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                id="password"
                type="password"
                className="border rounded w-full mt-6 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-left"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="text-red-500 text-xs italic mt-4">{error}</p>}
            {message && <p className="text-success-mm text-xs italic mt-4">{message}</p>}
            <div>
              {!isSubmitting && !isSuccess && (
                <button
                  type="submit"
                  className="btn-ghost-mm font-bold mt-20 py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                  COMENZAR
                </button>
              )}
              {isSubmitting && <span className="loading loading-spinner loading-lg text-mm mt-20"></span>}
              {isSuccess && (
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" height="96px" viewBox="0 -960 960 960" width="96px">
                  <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                </svg>
              )}
            </div>
          </form>
        </div>
        <div className="absolute inset-0 border-4 border-mm rounded transition duration-500 ease-in-out"></div>
      </div>
    </div>
  );
};

export default RegisterForm;
