import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const products = [
  { id: 1, name: 'Registrate',            price: '$10', image: 'fondos/fondo1.webp', mobileImage: 'fondos/fondo1-cel.webp', link: '/registrate/' },
  { id: 2, name: 'Proyector Led',         price: '$20', image: 'fondos/fondo2.webp', mobileImage: 'fondos/fondo2-cel.webp', link: '/productos/detalle-producto/100001/comprar' },
  { id: 3, name: 'Cerradura Inteligente', price: '$30', image: 'fondos/fondo3.webp', mobileImage: 'fondos/fondo3-cel.webp', link: '/productos/detalle-producto/100002/comprar' },
  { id: 4, name: 'Cafetera',              price: '$40', image: 'fondos/fondo4.webp', mobileImage: 'fondos/fondo4-cel.webp', link: '/productos/detalle-producto/100003/comprar' },
  { id: 5, name: 'Cortadora de Arbustos', price: '$50', image: 'fondos/fondo5.webp', mobileImage: 'fondos/fondo5-cel.webp', link: '/productos/detalle-producto/100004/comprar' },
];

function ProductSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? products.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === products.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 6000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="carousel w-full h-screen relative overflow-hidden background-mm">
      {products.map((product, index) => (
        <Link
          key={product.id}
          to={product.link}
          className={`carousel-item absolute inset-0 w-full h-full transition-opacity duration-1000 ${index === currentIndex ? 'opacity-100 z-20' : 'opacity-0 z-10'}`}
          style={{
            backgroundImage: `url(${isMobile ? product.mobileImage : product.image})`,
            backgroundSize: 'cover',
            backgroundPosition: isMobile ? 'top' : 'center',
            backgroundAttachment: 'fixed',
          }}
        >
          <div className="w-full h-full"></div>
        </Link>
      ))}
      <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2 z-40">
        <button onClick={handlePrevClick} className="btn btn-circle z-20">❮</button>
        <button onClick={handleNextClick} className="btn btn-circle z-20">❯</button>
      </div>
    </div>
  );
}

export default ProductSlider;
