import React, { useEffect, useRef } from 'react';

function ProductHeroOver() {
  const heroRef = useRef(null);

  useEffect(() => {
    if (heroRef.current) {
      heroRef.current.style.backgroundAttachment = 'fixed';
    }
  }, []);

  return (
    <div
      className="hero min-h-screen relative"
      ref={heroRef}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/fondos/Jacuzzi-ml582668.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="hero-overlay bg-opacity-30"></div>
      <div className="hero-content text-center text-neutral-content relative z-10">
        <div className="max-w-md mx-auto">
          <h2 className="mb-5 text-5xl font-bold">Jacuzzi Hidromasaje Inflable Spa</h2>
          <p className="mb-5">
          Lujo y comodidad: Perfecto para hasta 3 personas, alcanza 40°C rápidamente.
          </p>
          <button className="btn btn-primary">Quiero mi Jacuzzi</button>
        </div>
      </div>
    </div>
  );
}

export default ProductHeroOver;
