import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CarritoContext } from './context/CarritoContext';
import { supabase } from '../lib/supabaseClient';
import DOMPurify from 'dompurify';
import './BuyProduct.css';
import BuyProductSkeleton from './skeleton/BuyProductSkeleton';
import ProductosSimilares from './ProductosSimilares';
import { useInView } from 'react-intersection-observer';

const BuyProduct = ({ setCarritoActualizado }) => {
  const { sku } = useParams();
  const [producto, setProducto] = useState(null);
  const [cargando, setCargando] = useState(true);
  const [imagenPrincipal, setImagenPrincipal] = useState(null);
  const [nombreImagenHover, setNombreImagenHover] = useState('');
  const [cantidad, setCantidad] = useState(1);
  const { agregarItem } = useContext(CarritoContext);

  const procesarDescripcion = (descripcion) => {
    if (!descripcion) return '';

    let contenidoProcesado = descripcion
      // Procesar h2
      .replace(/\{([^}]+)\}/g, (match, titulo) => {
        return `<h2 class="color-mm text-center font-bold titulo-descripcion">${titulo}</h2>`;
      })
      // Procesar h3
      .replace(/\[([^\]]+)\]/g, (match, titulo) => {
        return `<h3 class="color-mm font-bold mb-5 mt-10">${titulo}</h3>`;
      })
      // Procesar párrafos
      .replace(/\|\|([^|]+)\|\|/g, (match, parrafo) => {
        return `<p class="mb-5" >${parrafo}</p>`;
      })
      // Procesar listas
      .replace(/^\* (.+)$/gm, '<li>$1</li>')
      .replace(/(<li>.*<\/li>\n?)+/g, match => `<ul>${match}</ul>`);

    return contenidoProcesado;
  };

  useEffect(() => {
    const fetchProducto = async () => {
      try {
        setCargando(true);
        const { data, error } = await supabase
          .from('productos')
          .select(`
            *,
            categoria:categorias!productos_categoria_id_fkey (categoria),
            categoria_2:categorias!productos_categoria_2_id_fkey (categoria)
          `)
          .eq('sku', sku)
          .single();

        if (error) {
          console.error('Error fetching producto:', error.message);
        } else {
          setProducto({
            ...data,
            categoria: data.categoria?.categoria || '',
            categoria_2: data.categoria_2?.categoria || '',
            descripcion: data.descripcion || '',
          });
          setImagenPrincipal(data.imagen_1);
          setNombreImagenHover(data.producto);
        }
      } catch (error) {
        console.error('Error fetching producto:', error.message);
      } finally {
        setCargando(false);
      }
    };

    fetchProducto();
  }, [sku]);

  const handleHoverImagen = (imagen) => {
    setImagenPrincipal(imagen);
  };

  const enviarMensajeWhatsApp = () => {
    if (producto) {
      const precioUnitario = formatPrice(producto.precio_cliente);
      const total = formatPrice(producto.precio_cliente * cantidad);
      let mensaje = `¡Hola! Estoy interesado en comprar el producto ${producto.producto} (SKU: ${producto.sku}). Precio unitario: ${precioUnitario}.`;

      if (cantidad > 1) {
        mensaje += ` Total a pagar: ${total} (por ${cantidad} unidades).`;
      }

      const whatsappURL = `https://wa.me/+56983820039?text=${encodeURIComponent(mensaje)}`;
      window.open(whatsappURL, '_blank');
    }
  };

  const incrementarCantidad = () => {
    setCantidad(cantidad + 1);
  };

  const decrementarCantidad = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  };

  const formatPrice = (price) => {
    if (!price) return '';
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const calcularTotal = () => {
    if (!producto || !producto.precio_cliente) return '';
    return formatPrice(producto.precio_cliente * cantidad);
  };

  const agregarAlCarrito = () => {
    if (!producto) {
      return;
    }

    const fechaActual = new Date();
    const fecha_carrito = fechaActual.toLocaleDateString();
    const hora_carrito = fechaActual.toLocaleTimeString(); 
    

    const nuevoProducto = { 
      ...producto, 
      cantidad, 
      precio: producto.precio_cliente,
      tipo: 'producto',
      imagen_1: producto.imagen_1,
      fecha_carrito,
      hora_carrito
    };
    agregarItem(nuevoProducto);
    setCarritoActualizado(true);
  
    const scrollTimeout = setTimeout(() => {
      const carritoPosition = window.innerHeight - 100;
      window.scrollTo({ top: carritoPosition, behavior: 'smooth' });
    }, 500);
  
    return () => clearTimeout(scrollTimeout);
  };

  const [titleRef, titleInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [imageRef, imageInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [infoRef, infoInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [descripcionRef, descripcionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [similaresRef, similaresInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  if (cargando) {
    return <BuyProductSkeleton />;
  }

  if (!producto) {
    return <p>No se encontró el producto.</p>;
  }

  return (
    <div className="comprar-producto-container mb-20">
      <h1 ref={titleRef} className={`title-mm ${titleInView ? 'animate-fade-in' : ''}`}>
        {producto.producto}
      </h1>
      <div ref={imageRef} className={`imagen-grande ${imageInView ? 'animate-slide-in-left' : ''}`}>
        {imagenPrincipal && <img src={imagenPrincipal} alt="Imagen principal" className="imagen-principal" />}
      </div>
      <div className="imagenes-pequenas">
        {[producto.imagen_1, producto.imagen_2, producto.imagen_3, producto.imagen_4, producto.imagen_5, producto.imagen_6].map((imagen, index) => (
          imagen && (
            <img
              key={index}
              src={imagen}
              alt={`Imagen ${index + 1}`}
              className={`imagen-pequena ${imageInView ? 'animate-fade-in' : ''}`}
              onMouseEnter={() => {
                handleHoverImagen(imagen);
                setNombreImagenHover(producto.producto);
              }}
            />
          )
        ))}
      </div>
      <div ref={infoRef} className={`info-producto ${infoInView ? 'animate-slide-in-right' : ''}`}>
        <h2>{nombreImagenHover}</h2>
        <p>SKU: {producto.sku}</p>
        <p>Precio: {formatPrice(producto.precio_cliente)} *</p>
        <div className="cantidad-container">
          <button className="btn-menos" onClick={decrementarCantidad}>-</button>
          <input type="text" value={cantidad} readOnly className="cantidad-input" />
          <button className="btn-mas" onClick={incrementarCantidad}>+</button>
        </div>
        <p className='mt-5 mb-5'>Total a pagar: {calcularTotal()}</p>
        <button className="btn btn-primary mr-5" onClick={enviarMensajeWhatsApp}>Comprar por WhatsApp</button>
        <button className="btn btn-accent" onClick={agregarAlCarrito}>Añadir al carrito</button>
        <br /><br /><br />
        <p>Por esta compra acumulas <span className='color-mm'>{producto.puntos_regalo}</span> puntos</p>
        <p>* Precio considera envío de hasta 5 mil pesos, si vives en un lugar que el envío es superior deberás costear la diferencia</p>

        <div className="participar-seccion mt-8 background-header p-10 border-mm rounded-lg">
          <h3 className="text-lg font-bold mb-2 color-mm">¿Sabías que puedes ganar este producto?</h3>
          <p className="mb-4">Participa en nuestros sorteos y podrías ganarte este producto!</p>
          <button 
            className="btn btn-secondary"
            onClick={() => window.location.href = `/productos/detalle-producto/${producto.sku}/participa`}
          >
            Participar ahora
          </button>
        </div>

        <div ref={descripcionRef} className={`producto-descripcion mt-20 ${descripcionInView ? 'animate-fade-in' : ''}`}>
          <div 
            className="descripcion-contenido"
            dangerouslySetInnerHTML={{ 
              __html: DOMPurify.sanitize(procesarDescripcion(producto.descripcion)) 
            }} 
          />
        </div>
      </div>
      
      <div ref={similaresRef} className={`productos-similares-container mt-40 ${similaresInView ? 'animate-fade-in-up' : ''}`}>
        <ProductosSimilares skuActual={sku} />
      </div>
    </div>
  );
};

export default BuyProduct;