// GoogleAnalytics.js
import { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Agregar el script de Google Analytics al montar el componente
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-MXT8T4CLNK';
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-MXT8T4CLNK');
    `;
    document.head.appendChild(script2);
  }, []);

  return null; // Este componente no renderiza nada visible
};

export default GoogleAnalytics;
