import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import './MyAccount.css';

const MyAccount = () => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [rut, setRut] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [celular, setCelular] = useState('');


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser()
        if (error) {
          throw error;
        }
        console.log(user.id);
        if (user) {
          setUser(user);

          // Fetch additional user data from your 'usuarios' table
          const { data: userData } = await supabase
            .from('usuarios')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (userData) {
            setUsername(userData.username);
            setBirthdate(userData.nacimiento);
            setRut(userData.rut);
            setName(userData.nombre);
            setLastName(userData.apellido);
            setCelular(userData.celular);
          }
        } else {
          console.log('No user signed in');
        }
      } catch (error) {
        console.error('Error fetching user:', error.message);
      }
    };

    fetchUserData();
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    if (!user) {
      console.error('No user signed in');
      return;
    }

    try {
      const { error } = await supabase
        .from('usuarios')
        .update({ username, nacimiento: birthdate, rut, nombre: name, apellido: lastName, celular: celular })
        .eq('user_id', user.id);

      if (error) {
        console.error('Error updating profile:', error.message);
      } else {
        alert('Profile updated successfully');
      }
    } catch (error) {
      console.error('Error updating profile:', error.message);
    }
  };

  return (
    <div className="my-account">
      <h1 className='title-mm'>Mis datos</h1>
      <form onSubmit={handleUpdateProfile}>
        <div>
          <label>Username</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <label>Fecha Nacimiento</label>
          <input type="date" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} />
        </div>
        <div>
          <label>RUT</label>
          <input type="text" value={rut} onChange={(e) => setRut(e.target.value)} />
        </div>
        <div>
          <label>Nombre</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label>Apellido</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div>
          <label>Celular</label>
          <input type="text" value={celular} onChange={(e) => setCelular(e.target.value)} />
        </div>
        

        
        <div className="input-container">
          <button className="btn btn-primary" type="submit">Guardar</button>
        </div>
      </form>

      
    </div>
  );
};

export default MyAccount;
