import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { supabase } from '../lib/supabaseClient';
import { CarritoContext } from './context/CarritoContext';
import AlertLogin from './AlertLogin'; 
import './Carrito.css';

const Carrito = ({ onCarritoVacio }) => {
  const { carrito, setCarrito } = useContext(CarritoContext);
  const [mostrarDatosBancarios, setMostrarDatosBancarios] = useState(false);
  const [mostrarBotonPagar, setMostrarBotonPagar] = useState(true);
  const [copiado, setCopiado] = useState(false);
  const [mostrarAlertLogin, setMostrarAlertLogin] = useState(false);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [montoTotal, setMontoTotal] = useState(0);
  const datosBancariosRef = useRef(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  // const [puntosTotales, setPuntosTotales] = useState(0);

  useEffect(() => {
    const carritoLocal = JSON.parse(localStorage.getItem('carrito')) || [];
    const carritoConCantidad = carritoLocal.map(item => ({
      ...item,
      cantidad: item.cantidad ? item.cantidad : 1
    }));
    setCarrito(carritoConCantidad);
  }, [setCarrito]);

  const guardarCarrito = (nuevoCarrito) => {
    localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
    setCarrito(nuevoCarrito);
  };

  const actualizarCantidad = (id, tipo, cantidad) => {
    const nuevoCarrito = carrito.map(item =>
      item.id === id && item.tipo === tipo ? { ...item, cantidad } : item
    );
    guardarCarrito(nuevoCarrito);
  };

  const eliminarItem = (id, tipo) => {
    const nuevoCarrito = carrito.filter(item => !(item.id === id && item.tipo === tipo));
    guardarCarrito(nuevoCarrito);
    if (nuevoCarrito.length === 0) {
      onCarritoVacio();
    }
  };

  const calcularTotal = () => {
    return carrito.reduce((total, item) =>
      total + (item.precio * item.cantidad || 0), 0
    );
  };

  const calcularPuntosTotales = () => {
    return carrito.reduce((total, item) => {
      if (item.tipo === 'producto') {
        return total + (item.puntos_regalo * item.cantidad || 0);
      } else if (item.tipo === 'ticket') {
        return total + (item.puntos * item.cantidad || 0);
      }
      return total;
    }, 0);
  };

  const formatPrice = (price) => {
    if (!price) return '';
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const copiarDatosBancarios = () => {
    const datosBancarios = `Banco: Banco Santander\nTipo Cuenta: Vista\nNúmero Cuenta: 005617518090\nTitular: MatiMarket\nRUT: 175180900\nEmail: matimarket.com@gmail.com`;
    navigator.clipboard.writeText(datosBancarios)
      .then(() => {
        setCopiado(true);
        setTimeout(() => setCopiado(false), 3000);
      })
      .catch(() => alert('Error al copiar los datos bancarios'));
  };

  const mostrarDatos = () => {
    setMostrarDatosBancarios(true);
    setMostrarBotonPagar(false);
    setTimeout(() => {
      if (datosBancariosRef.current) {
        datosBancariosRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  };

  const cancelarPago = () => {
    setMostrarDatosBancarios(false);
    setMostrarBotonPagar(true);
  };

  const ocultarAlertLogin = () => {
    setMostrarAlertLogin(false);
  };

  const transferenciaRealizada = async () => {
    const { data: { user } } = await supabase.auth.getUser();
  
    if (!user) {
      setModalAbierto(false);
      setMostrarAlertLogin(true);
      return;
    }
  
    if (!user.id) {
      return;
    }

    try {
      setIsSubmitting(true);

      const response = await fetch('/.netlify/functions/ingresarCompras', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ carrito, usuario: user.id }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Error al procesar la compra');
      }
      const codigoVenta = result.codigoVenta;
      console.log(codigoVenta);

      setIsSuccess(true);
      setIsSubmitting(false);
      const puntosTotales = calcularPuntosTotales();
      await sendEmailCarrito(user.email, carrito, montoTotal, puntosTotales, codigoVenta);
      setTimeout(() => {
        localStorage.removeItem('carrito');
        setCarrito([]);
        onCarritoVacio();
        setModalAbierto(false);
        navigate(result.link);
      }, 2000);
    } catch (error) {
      setServerErrorMessage(error.message); 
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const checkmark = document.querySelector('.checkmark');
      if (checkmark) {
        const handleAnimationEnd = () => {
          checkmark.classList.add('filled');
          checkmark.removeEventListener('animationend', handleAnimationEnd);
        };
        checkmark.addEventListener('animationend', handleAnimationEnd);
      }
    }
  }, [isSuccess]);

  const sendEmailCarrito = async (email, carrito, montoTotal, puntosTotales, codigoVenta ) => {
    try {
      const response = await fetch('/.netlify/functions/sendEmailCarrito', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, carrito, montoTotal, puntosTotales, codigoVenta  }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.error || 'Error al enviar el correo de confirmación.');
      }

    } catch (error) {
      console.error('Error en el proceso de registro:', error);
    }
  };


  const handleAbrirModal = () => {
    setMontoTotal(calcularTotal());
    setModalAbierto(true);
  };

  const handleCerrarModal = () => {
    setModalAbierto(false);
  };

  return (
    <div className="carrito-container mt-20">
      {mostrarAlertLogin && (
        <AlertLogin 
          description="Estás a un paso, solo necesitas ingresar con tu cuenta"
          onClose={ocultarAlertLogin}
        />
      )}

      {modalAbierto && (
        <dialog id="abonarModal" className="modal modal-bottom sm:modal-middle background-glass" open>
          <div className="modal-box border-mm border-2">
            <div className="modal-action">
              <p className="w-full">¿Estás seguro que ya realizaste la transferencia de ${formatPrice(montoTotal)}?</p>
            </div>
            <div className="modal-action flex justify-center mt-4">
              {!isSubmitting && !isSuccess && (
                <>
                  <button type="button" className="mr-20" onClick={handleCerrarModal}>No, cancelar</button>
                  <button type="submit" className="btn btn-primary" onClick={transferenciaRealizada}>Si, estoy seguro</button>
                </>
              )}
              {isSubmitting && (
                <div className="loading-container flex flex-col items-center">
                  <span className="loading loading-spinner loading-lg text-mm mt-2"></span>
                </div>
              )}
              {!isSubmitting && isSuccess && (
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" height="96px" viewBox="0 -960 960 960" width="96px">
                  <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                </svg>
              )}

            </div>
            {serverErrorMessage && (
                <div className="error-message mt-4 text-red-500">
                  <p>{serverErrorMessage}</p>
                  {serverErrorMessage.includes('producto') && (
                    <a href='/mis-pedidos' className="enlace-mm">
                      Ver Aquí
                    </a>
                  )}
                  {serverErrorMessage.includes('ticket') && (
                    <a href='/mis-puntos' className="enlace-mm">
                      Ver Aquí
                    </a>
                  )}
                </div>
              )}
          </div>
        </dialog>
      )}


      <h1 className='title-mm'>Carrito de Compras</h1>
      {carrito.length === 0 ? (
        <div>
          <p>Tu carrito está vacío</p>
          <Link to="/productos">Ir a Productos</Link>
        </div>
      ) : (
        <div>
          <div className="carrito-lista">
            {carrito.map((item, index) => (
              <div key={`${item.id}-${item.tipo}-${index}`} className="carrito-item">
                <div className="carrito-info">
                  <div className="imagen-container">
                    <p className="precio">{formatPrice(item.precio)}</p>
                    <img src={item.imagen_1} alt={item.producto || item.nombre} className="carrito-imagen" />
                  </div>
                  <div className="cantidad-container">
                    <button className="btn-menos" onClick={() => actualizarCantidad(item.id, item.tipo, item.cantidad - 1)} disabled={item.cantidad === 1}>-</button>
                    <p className='cantidad-item'>{item.cantidad}</p>
                    <button className="btn-mas" onClick={() => actualizarCantidad(item.id, item.tipo, item.cantidad + 1)}>+</button>
                  </div>
                  <p className="total">{formatPrice(item.precio * item.cantidad)}</p>
                  <button className="btn-eliminar" onClick={() => eliminarItem(item.id, item.tipo)}></button>
                </div>
                <p className="nombre-item w-full">{item.producto || item.nombre}</p>
              </div>
            ))}
            <p className='mt-5 flex justify-end'>Total a pagar: <span className='subtitle-mm'>$ {formatPrice(calcularTotal())}</span></p>
          </div>
          {mostrarBotonPagar && (
            <button className="btn btn-accent" onClick={mostrarDatos}>Pagar</button>
          )}
          {mostrarDatosBancarios && (
            <div className="datos-bancarios" ref={datosBancariosRef}>
              <div className="datos-header">
                <h2>Datos Transferencia</h2>
                <button className="btn-copiar" onClick={copiarDatosBancarios}>
                  {copiado ? (
                    <>
                      <span className="material-symbols-outlined" style={{ fontSize: '16px', marginRight: '5px' }}>done_all</span>
                      ¡Copiados!
                    </>
                  ) : (
                    <>
                      <span className="material-symbols-outlined" style={{ fontSize: '16px', marginRight: '5px' }}>content_copy</span>
                      Copiar Datos
                    </>
                  )}
                </button>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td><strong>Banco:</strong></td>
                    <td>Santander</td>
                  </tr>
                  <tr>
                    <td><strong>Cuenta Vista:</strong></td>
                    <td>005617518090</td>
                  </tr>
                  <tr>
                    <td><strong>Titular:</strong></td>
                    <td>MatiMarket</td>
                  </tr>
                  <tr>
                    <td><strong>RUT:</strong></td>
                    <td>17.518.090-0</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td>matimarket.com@gmail.com</td>
                  </tr>
                </tbody>
              </table>
              <div className="datos-footer">
                <button className="btn btn-ghost" onClick={cancelarPago}>Cancelar</button>
                <button className="btn btn-primary" onClick={handleAbrirModal}>Transferencia Realizada</button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Carrito;
