import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import './MisReferidos.css';
import MisReferidosSkeleton from './skeleton/MisReferidosSkeleton';

const MisReferidos = () => {
  const [referidos, setReferidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invitationLink, setInvitationLink] = useState('');
  const [copiado, setCopiado] = useState(false);

  useEffect(() => {
    const fetchReferidos = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();

        if (!user) {
          throw new Error('Usuario no autenticado');
        }

        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (userError) {
          throw userError;
        }

        if (!userData) {
          throw new Error('Usuario no encontrado en la tabla de usuarios');
        }

        const { data: referidosData, error: referidosError } = await supabase
          .from('referidos')
          .select('referido_codigo')
          .eq('referente_codigo', userData.codigo_referido);

        if (referidosError) {
          throw referidosError;
        }

        const referidosInfo = [];
        for (const referido of referidosData) {
          const referidoCodigo = referido.referido_codigo;
          const { data: referidoUserData, error: referidoUserError } = await supabase
            .from('usuarios')
            .select('email, fecha_registro')
            .eq('codigo_referido', referidoCodigo)
            .single();

          if (referidoUserError) {
            console.error(`Error al obtener la información del referido ${referidoCodigo}:`, referidoUserError.message);
            continue;
          }

          const email = referidoUserData.email;
          const maskedEmail = `${email.substring(0, 3)}******${email.substring(email.length - 13)}`;
          
          const { data: puntosData, error: puntosError } = await supabase
            .from('tickets_compras')
            .select('puntos')
            .eq('usuario', userData.codigo_referido)
            .eq('referido', referidoCodigo)
            .eq('forma_pago', 'referido');

          if (puntosError) {
            console.error(`Error al obtener los puntos del referido ${referidoCodigo}:`, puntosError.message);
            continue;
          }

          const puntosTotales = puntosData.reduce((total, compra) => total + (compra.puntos || 0), 0);
          
          const fechaRegistro = new Date(referidoUserData.fecha_registro);
          const tiempoTranscurrido = calcularTiempoTranscurrido(fechaRegistro);

          referidosInfo.push({
            email: maskedEmail,
            fechaRegistro: tiempoTranscurrido,
            puntos: puntosTotales
          });
        }

        const baseInvitationLink = `https://matimarket.com/registrate?key_referido=${userData.codigo_referido}`;
        setInvitationLink(baseInvitationLink);

        setReferidos(referidosInfo);
        setLoading(false);
      } catch (error) {
        console.error('Error al cargar los referidos:', error.message);
        setLoading(false);
      }
    };

    fetchReferidos();
  }, []);

  const calcularTiempoTranscurrido = (fecha) => {
    const ahora = new Date();
    const diferencia = ahora - fecha;
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const meses = Math.floor(dias / 30);
    const años = Math.floor(meses / 12);

    if (años > 0) {
      return `Hace ${años} ${años === 1 ? 'año' : 'años'}`;
    } else if (meses > 0) {
      return `Hace ${meses} ${meses === 1 ? 'mes' : 'meses'}`;
    } else {
      return `Hace ${dias} ${dias === 1 ? 'día' : 'días'}`;
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(invitationLink);
      setCopiado(true);
      setTimeout(() => setCopiado(false), 1000); // Ocultar el efecto después de 1 segundo
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error.message);
      alert('Error al copiar al portapapeles. Por favor, intenta nuevamente.');
    }
  };

  if (loading) {
    return <MisReferidosSkeleton />;
  }

  return (
    <div className='container-generico min-h-screen'>
      <h1 className='title-mm'>Mis Referidos</h1>
      {referidos.length === 0 ? (
        <p>Aún no tienes referidos</p>
      ) : (
        <table className="tabla-responsive referidos-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Tiempo desde registro</th>
              <th>Puntos Ganados</th>
            </tr>
          </thead>
          <tbody>
            {referidos.map((referido, index) => (
              <tr key={index}>
                <td>{referido.email}</td>
                <td>{referido.fechaRegistro}</td>
                <td><span className='color-mm'>{referido.puntos}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className='mt-10'>
        <label htmlFor="invitationLink">Invita a tus amigos a unirse:</label>
        <div className='input-container'>
          <input
            id="invitationLink"
            type="text"
            value={invitationLink}
            readOnly
            className="border-2 border-mm rounded rounded py-2 px-3 input-field"
          />
          <br />
          <button className="btn-copiar" onClick={copyToClipboard}>
            <span className={`material-symbols-outlined ${copiado ? 'pulse-effect done' : 'pulse-effect'}`} style={{ fontSize: '16px', marginRight: '5px' }}>
              {copiado ? 'done_all' : 'content_copy'}
            </span>
            {copiado ? '¡Copiado!' : 'Copiar'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MisReferidos;