import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { Link } from 'react-router-dom';
import './MisPuntos.css';
import MisPuntosSkeleton from './skeleton/MisPuntosSkeleton';

const MisPuntos = () => {
  const [movimientos, setMovimientos] = useState([]);
  const [totalPuntos, setTotalPuntos] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMovimientos = async () => {
      setLoading(true);
      setError(null);

      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        console.log("Usuario no logueado");
        setLoading(false);
        return;
      }

      console.log('User data:', user);
      console.log('User data ID:', user.id);

      if (!user.id) {
        console.error('El ID del usuario no está definido.');
        setLoading(false);
        return;
      }

      try {
        // Consultar datos del usuario actual desde la tabla 'usuarios'
        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (userError) {
          console.error('Error al obtener datos del usuario:', userError.message);
          throw userError;
        }

        if (!userData) {
          throw new Error('Usuario no encontrado en la tabla de usuarios');
        }

        // Consultar movimientos de tickets_compras con el nombre del ticket
        const { data: ticketsData, error: ticketsError } = await supabase
          .from('tickets_compras')
          .select(`
            id,
            ticket,
            fecha_compra,
            puntos,
            estado,
            cantidad,
            precio,
            total,
            forma_pago,
            referido,
            tickets_estados(puntos_validos),
            tickets(ticket,imagen_1)
          `)
          .eq('usuario', userData.codigo_referido);

        if (ticketsError) {
          setError(ticketsError.message);
          setLoading(false);
          return;
        }

        const ticketsMovimientos = ticketsData.map(ticket => ({
          id: ticket.id,
          item: ticket.tickets.ticket,
          imagen: ticket.tickets.imagen_1,
          fecha_compra: ticket.fecha_compra,
          cantidad: ticket.cantidad,
          precio: ticket.precio,
          total: ticket.total,
          estado: ticket.estado,
          forma_pago: ticket.forma_pago,
          puntos: ticket.puntos,
          referido: ticket.referido,
          suma_puntos: ticket.tickets_estados?.puntos_validos ? 'Sí' : 'No'
        }));

        const totalTicketsPuntos = ticketsMovimientos
          .filter(ticket => ticket.suma_puntos === 'Sí')
          .reduce((acc, ticket) => acc + (ticket.puntos || 0), 0);

        // Consultar movimientos de productos_compras con el nombre del producto
        const { data: productosData, error: productosError } = await supabase
          .from('productos_compras')
          .select(`
            id,
            producto,
            fecha_compra,
            puntos,
            estado,
            cantidad,
            precio,
            total,
            forma_pago,
            productos_estados(puntos_validos),
            productos(producto,imagen_1, sku)
          `)
          .eq('usuario', userData.codigo_referido);

        if (productosError) {
          setError(productosError.message);
          setLoading(false);
          return;
        }

        const productosMovimientos = productosData.map(producto => ({
          id: producto.id,
          item: producto.productos.producto,
          imagen: producto.productos.imagen_1,
          fecha_compra: producto.fecha_compra,
          cantidad: producto.cantidad,
          precio: producto.precio,
          total: producto.total,
          estado: producto.estado,
          puntos: producto.puntos,
          forma_pago: producto.forma_pago,
          suma_puntos: producto.productos_estados?.puntos_validos ? 'Sí' : 'No',
          sku: producto.productos.sku
        }));

        const totalProductosPuntos = productosMovimientos
          .filter(producto => producto.suma_puntos === 'Sí')
          .reduce((acc, producto) => acc + (producto.puntos || 0), 0);

        // Consultar movimientos de puntos abonados a sorteos
        const { data: sorteosData, error: sorteosError } = await supabase
          .from('tickets_participantes')
          .select(`
            id,
            created_at,
            puntos_aportados,
            puntos_inicio,
            puntos_final,
            producto,
            sorteo,
            productos(producto, imagen_1, sku),
            sorteos(key, producto, fecha_sorteo, productos(imagen_1))
          `)
          .eq('usuario', userData.codigo_referido);

        if (sorteosError) {
          setError(sorteosError.message);
          setLoading(false);
          return;
        }

        const sorteosMovimientos = sorteosData.map(sorteo => ({
          id: sorteo.id,
          item: `Sorteo ${sorteo.sorteos?.key || 'Desconocido'}`,
          imagen: sorteo.sorteos?.productos?.imagen_1 || sorteo.productos?.imagen_1,
          fecha_compra: sorteo.created_at,
          puntos: -sorteo.puntos_aportados, // Puntos negativos
          estado: 'Participación en sorteo',
          suma_puntos: 'No',
          producto: sorteo.productos?.producto || sorteo.sorteos?.producto || '-',
          puntos_inicio: sorteo.puntos_inicio,
          puntos_final: sorteo.puntos_final,
          fecha_sorteo: sorteo.sorteos?.fecha_sorteo,
          sku: sorteo.productos?.sku
        }));

        const totalSorteosPuntos = sorteosMovimientos.reduce((acc, sorteo) => acc + sorteo.puntos, 0);

        const allMovimientos = [...ticketsMovimientos, ...productosMovimientos, ...sorteosMovimientos].sort(
          (a, b) => new Date(b.fecha_compra) - new Date(a.fecha_compra)
        );

        setMovimientos(allMovimientos);
        setTotalPuntos(totalTicketsPuntos + totalProductosPuntos + totalSorteosPuntos);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchMovimientos();
  }, []);

  const imagenPorDefecto = "https://ybxvdpxyxbfqioanfrbp.supabase.co/storage/v1/object/public/matimarket/sin-imagen.png?t=2024-07-28T01%3A40%3A22.198Z";

  const renderImagen = (movimiento) => {
    const imagenSrc = movimiento.imagen || imagenPorDefecto;
    const contenidoImagen = (
      <img 
        src={imagenSrc} 
        alt={movimiento.producto || "Imagen del producto"} 
        className='product-image'
        onError={(e) => { e.target.onerror = null; e.target.src = imagenPorDefecto; }}
      />
    );

    if (movimiento.sku) {
      return (
        <Link to={`/productos/detalle-producto/${movimiento.sku}/participa`}>
          {contenidoImagen}
        </Link>
      );
    } else {
      return (
        <Link to="/comprar-tickets">
          {contenidoImagen}
        </Link>
      );
    }
  };

  if (loading) return <MisPuntosSkeleton />;

  if (error) return <p className='mt-40'>Error: {error}</p>;

  return (
    <div className="container-generico min-h-screen">
      <h1 className='title-mm'>Mis Puntos <span className='color-mm'>{totalPuntos}</span></h1>
      {movimientos.length === 0 ? (
        <p>Aún no tienes puntos. <Link to="/comprar-tickets" className="enlace-mm">Comprar puntos aquí</Link></p>
      ) : (
        <table className="tabla-responsive tabla-mis-puntos">
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Item</th>
              <th>Fecha</th>
              <th>Producto</th>
              <th>Fecha Sorteo</th>
              <th>Puntos Inicio</th>
              <th>Puntos Final</th>
              <th>Estado</th>
              <th>Puntos</th>
              <th>Suma Puntos</th>
            </tr>
          </thead>
          <tbody>
            {movimientos.map(movimiento => (
              <tr key={movimiento.id}>
                <td data-label="Imagen">
                  {renderImagen(movimiento)}
                </td>
                <td data-label="Item">{movimiento.item}</td>
                <td data-label="Fecha">{new Date(movimiento.fecha_compra).toLocaleDateString()}</td>
                <td data-label="Producto">
                  {movimiento.sku ? (
                    <Link to={`/productos/detalle-producto/${movimiento.sku}/participa`}>
                      {movimiento.producto}
                    </Link>
                  ) : movimiento.item && movimiento.item.toLowerCase().includes('ticket') ? (
                    <Link to="/comprar-tickets">
                      {movimiento.item}
                    </Link>
                  ) : (
                    movimiento.producto || '-'
                  )}
                </td>
                <td data-label="Fecha Sorteo">{movimiento.fecha_sorteo ? new Date(movimiento.fecha_sorteo).toLocaleDateString() : '-'}</td>
                <td data-label="Puntos Inicio">{movimiento.puntos_inicio || '-'}</td>
                <td data-label="Puntos Final">{movimiento.puntos_final || '-'}</td>
                <td data-label="Estado">{movimiento.estado}</td>
                <td data-label="Puntos">{movimiento.puntos}</td>
                <td data-label="Suma Puntos">{movimiento.suma_puntos}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MisPuntos;