import React from 'react';

function MisReferidosSkeleton() {
  return (
    <div className="container-generico animate-pulse flex flex-col items-center min-h-screen mt-10">
      <div className="mb-8">
        <div className="h-10 bg-gray-300 rounded w-64 mx-auto"></div>
      </div>
      
      <div className="w-full max-w-4xl px-4">
        <div className="overflow-x-auto">
          <table className="tabla-responsive tabla-mis-referidos w-full">
            <thead>
              <tr className="text-center">
                {[...Array(5)].map((_, index) => (
                  <th key={index} className="h-8 bg-gray-300 rounded"></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex} className="text-center">
                  {[...Array(5)].map((_, cellIndex) => (
                    <td key={cellIndex} className="p-2">
                      <div className="h-6 bg-gray-300 rounded mx-auto"></div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MisReferidosSkeleton;