import React, { useEffect, useState, useRef } from 'react';

function ProductHeroRight() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const carouselRef = useRef(null);

  const images = [
    "/productos/autos/compresor-de-aire-para-inflar-neumaticos-de-autos.png",
    "/productos/autos/inflador-de-neumaticos.png",
    "/productos/autos/hidrolavadora.png",
    "/productos/autos/colchon-inflable-para-auto.png",
    "/productos/autos/espejo-retrovisor-con-camara.png",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isDragging, images.length]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="hero min-h-screen background-mm">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div
          ref={carouselRef}
          className="w-full max-w-md carousel rounded-box"
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          {images.map((image, index) => (
            <div key={index} className={`carousel-item w-full ${index === currentIndex ? '' : 'hidden'}`}>
              <img src={image} className="w-full h-auto rounded-lg shadow-2xl" style={{ objectFit: 'contain' }} alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </div>
        <div>
          <h2 className="text-5xl font-bold">¡Accesorios que Transforman tu Auto!</h2>
          <p className="py-6">Mejora cada viaje con nuestra selección de accesorios de alta calidad para tu vehículo. Desde tecnología avanzada hasta elementos de confort, encuentra todo lo que necesitas para personalizar tu auto.</p>
          <button className="btn btn-primary">Explora Ahora</button>
        </div>
      </div>
    </div>
  );
}

export default ProductHeroRight;
