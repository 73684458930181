import React, { useEffect, useState, useCallback } from 'react';
import './Products.css';
import { supabase } from '../lib/supabaseClient';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';
import ProductSkeleton from './skeleton/ProductSkeleton';

function Productos() {
  const [productos, setProductos] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortOption, setSortOption] = useState('aleatorio');
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [displayedProductos, setDisplayedProductos] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [showOnlyConParticipantes, setShowOnlyConParticipantes] = useState(false); // Estado para el filtro de participantes
  const [productosConParticipantes, setProductosConParticipantes] = useState(0); // Contador de productos con participantes
  const [isLoading, setIsLoading] = useState(true);

  const sortProductos = useCallback((option) => {
    let sorted = [...productos];

    if (option === 'nombre') {
      sorted.sort((a, b) => a.producto.localeCompare(b.producto));
    } else if (option === 'categoria') {
      sorted.sort((a, b) => {
        const categoriaA = (a.categoria || '') + (a.categoria_2 ? `, ${a.categoria_2}` : '');
        const categoriaB = (b.categoria || '') + (b.categoria_2 ? `, ${b.categoria_2}` : '');
        return categoriaA.localeCompare(categoriaB);
      });
    } else if (option === 'precio-asc') {
      sorted.sort((a, b) => a.precio_cliente - b.precio_cliente);
    } else if (option === 'precio-desc') {
      sorted.sort((a, b) => b.precio_cliente - a.precio_cliente);
    } else if (option === 'aleatorio') {
      sorted.sort(() => Math.random() - 0.5);
    }

    setDisplayedProductos([...sorted]);
  }, [productos]);

  useEffect(() => {
    const fetchProductos = async () => {
      setIsLoading(true);
      try {
        let { data: productos, error } = await supabase
          .from('productos')
          .select(`
            *,
            categoria:categorias!productos_categoria_id_fkey (categoria),
            categoria_2:categorias!productos_categoria_2_id_fkey (categoria)
          `)
          .eq('disponible', true);

        if (error) {
          console.error('Error fetching productos:', error);
          return;
        }

        const productosConCategorias = productos.map(producto => ({
          ...producto,
          categoria: producto.categoria?.categoria || '',
          categoria_2: producto.categoria_2?.categoria || '',
          puntos_final: 0,
        }));

        const productosOrdenados = productosConCategorias.sort(() => Math.random() - 0.5);

        setProductos(productosOrdenados);
        setDisplayedProductos(productosOrdenados);

        const categoriasUnicas = new Set();
        productosConCategorias.forEach(producto => {
          if (producto.categoria) categoriasUnicas.add(producto.categoria);
          if (producto.categoria_2) categoriasUnicas.add(producto.categoria_2);
        });

        const categoriasOrdenadas = Array.from(categoriasUnicas).sort();
        setCategorias(categoriasOrdenadas);

        // Obtener puntos_final para cada producto
        await fetchPuntosFinal(productosConCategorias);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching productos:', error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchPuntosFinal = async (productosConCategorias) => {
      try {
        const { data: sorteos, error: sorteosError } = await supabase
          .from('sorteos')
          .select('key, producto')
          .eq('estado', 'Disponible');

        if (sorteosError) {
          console.error('Error fetching sorteos:', sorteosError);
          return;
        }

        const sorteoKeys = sorteos.map(sorteo => sorteo.key);

        const { data: tickets, error: ticketsError } = await supabase
          .from('tickets_participantes')
          .select('producto, puntos_final, sorteo')
          .in('sorteo', sorteoKeys)
          .order('puntos_final', { ascending: false });

        if (ticketsError) {
          console.error('Error fetching puntos_final:', ticketsError);
          return;
        }

        const puntosFinalesPorProducto = {};

        tickets.forEach(ticket => {
          if (!puntosFinalesPorProducto[ticket.producto]) {
            puntosFinalesPorProducto[ticket.producto] = ticket.puntos_final;
          }
        });

        const updatedProductos = productosConCategorias.map(producto => ({
          ...producto,
          puntos_final: puntosFinalesPorProducto[producto.sku] || 0,
        }));

        setProductos(updatedProductos);

        const countProductosConParticipantes = updatedProductos.filter(producto => producto.puntos_final > 0).length;
        setProductosConParticipantes(countProductosConParticipantes);
      } catch (error) {
        console.error('Error fetching puntos_final:', error.message);
      }
    };

    fetchProductos();
  }, []);

  useEffect(() => {
    sortProductos(sortOption);
  }, [sortOption, sortProductos]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSortChange = (e) => {
    const option = e.target.value;
    setSortOption(option);
  };

  const handleProductHover = (id) => {
    setHoveredProductId(id);
  };

  const handleProductLeave = () => {
    setHoveredProductId(null);
  };

  const handleToggleConParticipantes = () => {
    setShowOnlyConParticipantes(!showOnlyConParticipantes);
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const filteredProductos = displayedProductos.filter((producto) => {
    const categoriaA = (producto.categoria || '') + (producto.categoria_2 ? `, ${producto.categoria_2}` : '');
    const matchFilter = (
      (producto.producto && producto.producto.toLowerCase().includes(filter.toLowerCase())) ||
      categoriaA.toLowerCase().includes(filter.toLowerCase()) ||
      (producto.sku && producto.sku.toLowerCase().includes(filter.toLowerCase()))
    );
    const matchCategory = categoryFilter === '' || producto.categoria === categoryFilter || producto.categoria_2 === categoryFilter;

    // Filtrar por participantes si el filtro está activado
    const tieneParticipantes = producto.puntos_final > 0;
    return matchFilter && matchCategory && (!showOnlyConParticipantes || tieneParticipantes);
  });

  return (
    <div className="productos-container mb-20 background-mm">
      <h1 className="title-mm">PRODUCTOS</h1>
      <div id="div_buscador" className="filter-sort-container">
      <div className="input-container">
        <input
          type="text"
          placeholder="Buscar..."
          value={filter}
          onChange={handleFilterChange}
          className="filter-input"
        />
      </div>
      <div className="select-container">
        <select value={sortOption} onChange={handleSortChange} className="sort-select">
          <option value="aleatorio">Orden Aleatorio</option>
          <option value="nombre">Ordenar por Nombre</option>
          <option value="categoria">Ordenar por Categoría</option>
          <option value="precio-asc">Menor Precio</option>
          <option value="precio-desc">Mayor Precio</option>
        </select>
        <select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)} className="category-select">
          <option value="">Todas las Categorías</option>
          {categorias.map(categoria => (
            <option key={categoria} value={categoria}>{categoria}</option>
          ))}
        </select>
      </div>
    </div>


      <div className="indicator">
          <span className="indicator-item badge badge-mm bg-header absolute top-4 left-10 -mt-2 -mr-2">{productosConParticipantes}</span>
        
        <label className="checkbox-container">
          <img
            src={showOnlyConParticipantes ? '/img/maticoins.png' : '/img/maticoins-apagado.png'}
            alt="Filtro de Participantes"
            onClick={handleToggleConParticipantes}
            className="filtro-participantes-img"
          />
        </label>
        </div>
        
        <div className="productos-grid">
        <TransitionGroup component={null}>
          {isLoading ? (
            Array.from({ length: 12 }).map((_, index) => (
              <CSSTransition key={index} timeout={500} classNames="producto">
                <ProductSkeleton />
              </CSSTransition>
            ))
          ) : (
           filteredProductos.map((producto) => {
            const progressValue = producto.puntos_final ? (producto.puntos_final / producto.puntos) * 100 : 0;
            return (
              <CSSTransition key={producto.id} timeout={500} classNames="producto">
                <div
                  className="card w-96 background-mm shadow-xl producto-card mt-10"
                  onMouseEnter={() => handleProductHover(producto.id)}
                  onMouseLeave={handleProductLeave}
                >
                  <figure>
                    <img
                      src={producto.imagen_1 ? producto.imagen_1 : `/productos/herramientas/kit-taladro-img2.png`}
                      alt={producto.producto}
                      className="producto-imagen"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">{truncateString(producto.producto, 40)}</h2>
                  </div>
                  <div className="card-actions justify-center mb-5">
                    {producto.categoria && <div className="badge badge-outline categoria-badge">{producto.categoria}</div>}
                    {producto.categoria_2 && <div className="badge badge-outline categoria-badge">{producto.categoria_2}</div>}
                  </div>
                  <div className="producto-info-container">
                    <div className="producto-precio-seccion">
                      <span>Precio</span>
                      <p>{producto.precio_cliente.toLocaleString()}</p>
                    </div>
                    <div className="producto-puntos-seccion">
                      <span>Puntos</span>
                      <p>{producto.puntos.toLocaleString()}</p>
                    </div>
                  </div>
                  <CSSTransition in={hoveredProductId === producto.id} timeout={300} classNames="fade">
                    <div className="btn-container">
                      <Link to={`/productos/detalle-producto/${producto.sku}/comprar`} className="producto-link">
                        <button className="btn btn-primary">Comprar</button>
                      </Link>
                      <Link to={`/productos/detalle-producto/${producto.sku}/participa`} className="producto-link">
                        <button className="btn btn-accent mt-5">Participar</button>
                      </Link>
                    </div>
                  </CSSTransition>
                  <div className="progress-container">
                    <progress className="progress progress-mm w-56" value={progressValue} max={100}></progress>
                  </div>
                </div>
              </CSSTransition>
            );
          })
        )}
        </TransitionGroup>
      </div>
    </div>
  );
}

export default Productos;