import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CarritoContext } from './context/CarritoContext'; // Adjust the path as needed
import './CarritoIcono.css';

const CarritoIcono = ({ onCloseDrawer }) => {
  const { carrito } = useContext(CarritoContext);
  const numProductosCarrito = carrito.reduce((total, item) => total + item.cantidad, 0);

  useEffect(() => {
    const cartRef = document.getElementById('cart-icon');
    if (cartRef) {
      cartRef.style.top = numProductosCarrito > 0 ? '3rem' : '-30rem';
    }
  }, [numProductosCarrito]);

  return (
    <div
      id="cart-icon"
      className="flex items-center justify-center fixed transition-all duration-300 right-1/2 transform translate-x-1/2 "
    >
      <Link to="/carrito" onClick={onCloseDrawer} className="relative flex justify-center items-center">
        <img className="w-12 mr-1" src="/img/carrito-matimarket.com.png" alt="Carrito de Compras" />
        {numProductosCarrito > 0 && (
          <span className="badge badge-mm absolute top-0 right-0 -mt-2 -mr-2 bg-header">{numProductosCarrito}</span>
        )}
      </Link>
    </div>
  );
};

export default CarritoIcono;
