import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../lib/supabaseClient';
import { CarritoContext } from './context/CarritoContext';
import TicketsSkeleton from './skeleton/TicketsSkeleton';
import './ComprarTickets.css';

const ComprarTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { agregarItem } = useContext(CarritoContext);

  useEffect(() => {
    const fetchTickets = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase.from('tickets').select('*').eq('publico', true).order('id');;
        if (error) {
          console.error('Error fetching tickets:', error.message);
        } else {
          setTickets(data);
        }
      } catch (error) {
        console.error('Error fetching tickets:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const agregarAlCarrito = (ticket) => {
    const productoDescripcion = `Ticket ${ticket.ticket} por ${ticket.puntos} puntos c/u`;
    const fechaActual = new Date();
    const fecha_carrito = fechaActual.toLocaleDateString();
    const hora_carrito = fechaActual.toLocaleTimeString();

    agregarItem({
      ...ticket,
      producto: productoDescripcion,
      cantidad: 1,
      tipo: 'ticket',
      fecha_carrito,
      hora_carrito
    });
  };

  return (
    <div className="comprar-tickets-container">
      <h1 className='title-mm'>Comprar Tickets</h1>
      {isLoading ? (
        <TicketsSkeleton /> // Usa el componente TicketsSkeleton importado
      ) : (
      <div className="tickets-container">
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className="card card-ticket bg-header w-64 shadow-xl"
            onMouseEnter={(e) => {
              const img = e.currentTarget.querySelector('img');
              if (img) img.src = ticket.imagen_1;
            }}
            onMouseLeave={(e) => {
              const img = e.currentTarget.querySelector('img');
              if (img) img.src = ticket.imagen_2;
            }}
          >
            <figure className="px-1 pt-1">
              <img
                src={ticket.imagen_2}
                alt={ticket.ticket}
                className="rounded-xl"
              />
            </figure>
            <button className="buy-button" onClick={() => agregarAlCarrito(ticket)}>AGREGAR AL CARRITO</button>
            <div className="card-body items-center text-center">
              <h2 className="card-title" style={{ color: `${ticket.hex}`, textTransform: 'uppercase', textShadow: '1px 1px 2px white' }}>
                {ticket.ticket}
              </h2>
              <p>Precio: $ {Number(ticket.precio).toLocaleString('es-ES')}</p>
              <p><span className='color-mm'>{Number(ticket.puntos).toLocaleString('es-ES')}</span> puntos</p>
            </div>
          </div>
        ))}
        </div>
      )}
    </div>
  );
};

export default ComprarTickets;
