import React from 'react';

function BuyProductSkeleton() {
  return (
    <div className="container-generico animate-pulse flex flex-col items-center justify-center min-h-screen mt-10">
    <div className="mb-8">
      <div className="h-10 bg-gray-300 rounded w-80 mx-auto"></div>
    </div>
    
    <div className="h-64 bg-gray-300 rounded w-1/4 mb-8"></div>
     
      {/* 5 cuadros pequeños */}
      <div className="flex justify-center space-x-2 mb-4">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="h-16 w-16 bg-gray-300 rounded"></div>
        ))}
      </div>
      
      {/* 3 textos */}
      <div className="space-y-2 mb-8">
        <div className="h-4 bg-gray-300 rounded w-36"></div>
        <div className="h-4 bg-gray-300 rounded w-36"></div>
        <div className="h-4 bg-gray-300 rounded w-36"></div>
      </div>
      
      {/* 3 botones */}
      <div className="flex justify-center space-x-2 mb-4">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="h-10 bg-gray-300 rounded w-12"></div>
        ))}
      </div>
      
      {/* Texto adicional */}
      <div className="h-4 bg-gray-300 rounded w-48 mx-auto mb-4"></div>
      <br />
      {/* 2 botones finales */}
      <div className="flex justify-center space-x-4">
        <div className="h-10 bg-gray-300 rounded w-24"></div>
        <div className="h-10 bg-gray-300 rounded w-24"></div>
      </div>
      
    </div>
  );
}

export default BuyProductSkeleton;